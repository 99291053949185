import {SxProps, Theme} from "@mui/material";

export const STYLES = {
  mockTestTableFeaturePaper: {
    p: "20px",
    minHeight: "592px",
    display: "flex",
    flexDirection: "column",
  } as SxProps<Theme>,
};
