import {useLocation} from "react-router-dom";
import {EXAM_PAGE_ROUTES, ExamPageSection} from "./routes";

export const useGetExamPageSection = (): ExamPageSection | undefined => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Find matching ExamPageSection based on the path
  if (currentPath.includes(EXAM_PAGE_ROUTES[ExamPageSection.MOCK_TEST_EXPLORE]))
    return ExamPageSection.MOCK_TEST_EXPLORE;
  if (currentPath.includes(EXAM_PAGE_ROUTES[ExamPageSection.PLANNER]))
    return ExamPageSection.PLANNER;
  if (currentPath.includes(EXAM_PAGE_ROUTES[ExamPageSection.PROBLEM_SET]))
    return ExamPageSection.PROBLEM_SET;
  return undefined;
};
