import {SxProps, Theme} from "@mui/material";

export const STYLES = {
  paper: {
    p: "20px",
    position: "relative",
  } as SxProps<Theme>,
  tagIconBox: {
    width: 40,
    height: 36,
    borderRadius: "8px",
    bgcolor: "primary.light",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as SxProps<Theme>,
  takeTestButton: {
    minWidth: "144px",
    height: "48px",
  } as SxProps<Theme>,
  illustrationBox: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: "auto",
    minWidth: "40%",
    backgroundImage: `url(${process.env.PUBLIC_URL}/mockTest/test_overview_illustration.png)`,
    backgroundSize: "contain",
    backgroundPosition: "right center",
    backgroundRepeat: "no-repeat",
  } as SxProps<Theme>,
};
