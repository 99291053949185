import {ExamType} from "./enums";

type ExamDetailProps = {
  header: string;
  route: string;
  type: ExamType;
};

export const examDetails: Record<ExamType, ExamDetailProps> = {
  [ExamType.JEE_MAINS]: {
    header: "JEE Mains",
    route: "jee-mains",
    type: ExamType.JEE_MAINS,
  },
  [ExamType.CAT]: {
    header: "CAT",
    route: "cat",
    type: ExamType.CAT,
  },
};

export function getExamTypeFromId(id: string): ExamType | null {
  Object.values(examDetails).map(exam => {
    if (exam.header === id) {
      return exam.type;
    }
  });
  return null;
}

export function getExamTypeFromRouteName(routeName?: string): ExamType | null {
  // Object.values(examDetails).map(exam => {
  //   if (exam.route === routeName) {
  //     return exam.type;
  //   }
  // });
  if (routeName === examDetails[ExamType.CAT].route) return ExamType.CAT;
  if (routeName === examDetails[ExamType.JEE_MAINS].route)
    return ExamType.JEE_MAINS;

  return null;
}
