import React, {Suspense, lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {examDetails} from "./config/exam";
import {ExamType} from "./config/enums";
import NotFound from "./pages/not-found/NotFound";
import ExamPage from "./pages/exam/common/ExamPage";
import LoginDialog from "./features/login-dialog";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "./store/store";
import {getProfileHandler} from "./api/user/get-profile";
import {setLoggedOut, setProfile} from "./store/actions/userSlice";
import {useDeviceTypeUpdater} from "./utils/device-details/update-device-type";
import MockTestPage from "./pages/exam/common/section/mock-test/MockTestPage";
import {
  EXAM_PAGE_ROUTES,
  ExamPageSection,
} from "./pages/exam/common/navigation/routes";
import MockTestDetailsPage from "./pages/exam/common/section/mock-test/MockTestDetailsPage";
import AttemptExamPage from "./pages/exam/common/section/attempt-exam/AttemptExamPage";
import {mockTestApiSlice} from "./features/mock-test-table/store/api/mockTestApiSlice";

/** Pages */
const LandingPage = lazy(() => import("./pages/landing/LandingPage"));
const Problemset = lazy(
  () => import("./pages/exam/common/section/problemset/Problemset"),
);

function App() {
  const exams = (
    Object.values(ExamType).filter(x => typeof x === "number") as ExamType[]
  ).map(key => examDetails[key]);
  useDeviceTypeUpdater();

  const {isLoggedIn, data} = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    async function getProfile() {
      if (data !== null) {
        // do Nothing
      } else if (isLoggedIn === false) {
        // do Nothing
      } else {
        const res = await getProfileHandler();
        // dispatch(mockTestApiSlice.util.invalidateTags(["MockTest"]));
        if (res === null) {
          dispatch(setLoggedOut());
        } else {
          dispatch(setProfile(res));
        }
      }
    }
    getProfile();
  }, [isLoggedIn]);

  return (
    <div>
      <Suspense fallback={<>Loading...</>}>
        <LoginDialog />
        <Routes>
          <Route index element={<LandingPage />} />

          <Route path=":examPath" element={<ExamPage />}>
            {exams.map(exam => (
              <React.Fragment key={exam.type}>
                <Route index element={<Problemset examType={exam.type} />} />
                <Route
                  path="solve/:problemId"
                  element={
                    <Problemset showProblemDialog examType={exam.type} />
                  }
                />
              </React.Fragment>
            ))}
            <Route path="planner" element={<NotFound />} />
            <Route
              path={EXAM_PAGE_ROUTES[ExamPageSection.MOCK_TEST_INSTRUCTIONS]}
              element={<MockTestDetailsPage />}
            />
            <Route
              path={
                EXAM_PAGE_ROUTES[
                  ExamPageSection.MOCK_TEST_EXPLORE_WITH_CATEGORY
                ]
              }
              element={<MockTestPage />}
            />
            <Route
              path={EXAM_PAGE_ROUTES[ExamPageSection.MOCK_TEST_EXPLORE]}
              element={<MockTestPage />}
            />
            <Route path="*" element={<Navigate to={"."} />} />
          </Route>
          <Route path="test/attempt/:testId" element={<AttemptExamPage />} />

          {/* Fallback route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
