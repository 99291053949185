import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {SimplePaletteColorOptions} from "@mui/material";
import {SymbolCodepoints} from "react-material-symbols";
import {
  stringToAttemptStatusType,
  stringToProblemDifficultyType,
} from "../../config/enums";
import {statusTheme} from "../../config/themes/status-theme";
import {statusIcons} from "../../assets/icons/ProblemIcons";
import {difficultyTheme} from "../../config/themes/difficulty-theme";
import {SyllabusStructType} from "../../features/problemset-table/store/actions/SyllabusStructType";
import {
  AttemptStatusFilterType,
  ChapterFilterType,
  DifficultyFilterType,
  PatternFilterType,
  SubjectFilterType,
  TopicFilterType,
} from "../../features/problemset-table/store/actions/tableFiltersSlice";
import {PyqMockDropdownYearListType} from "../../features/mock-test-table/components/search/search-button-dropdown/PyqMockDropdownUI";

// Response types from API
type NameIdType = {
  name: string;
  reference_id: string;
  metadata: {
    icon: SymbolCodepoints;
    color: SimplePaletteColorOptions;
    altText: string;
  };
};

type ChapterType = {
  name: string;
  reference_id: string;
  questions_count: number;
  topics_count: number;
  metadata: {
    icon: SymbolCodepoints;
    color: SimplePaletteColorOptions;
    altText: string;
  };
};

type ConfigResponse = {
  subjects?: NameIdType[];
  chapters?: Record<string, ChapterType[]>;
  topics?: Record<string, NameIdType[]>;
  question_types?: NameIdType[];
  user_question_status?: NameIdType[];
  difficulty_types?: NameIdType[];
  previous_years?: {id: string; label: string; description: string}[];
};

// Transformed response type
type TransformedConfigResponse = {
  syllabus?: SyllabusStructType;
  subjects: SubjectFilterType[];
  chapters: Record<SubjectFilterType["id"], ChapterFilterType[]>;
  topics: Record<ChapterFilterType["id"], TopicFilterType[]>;
  patterns: PatternFilterType[];
  attemptStatus: AttemptStatusFilterType[];
  difficulty: DifficultyFilterType[];
  pyqMock: {
    years: PyqMockDropdownYearListType[];
  };
};

function transformConfigResponse(
  res: ConfigResponse,
): TransformedConfigResponse {
  const topics: Map<ChapterFilterType["id"], TopicFilterType[]> = new Map();
  const chapters: Map<SubjectFilterType["id"], ChapterFilterType[]> = new Map();

  const subjects =
    res.subjects?.map(item => {
      // Process chapters for each subject
      const chaptersPerSubject = res.chapters?.[item.reference_id] ?? [];
      chapters.set(
        item.reference_id,
        chaptersPerSubject.map(chapter => {
          // Process topics for each chapter
          const topicsPerChapter = res.topics?.[chapter.reference_id];
          if (topicsPerChapter !== undefined) {
            topics.set(
              chapter.reference_id,
              topicsPerChapter.map(topic => ({
                id: topic.reference_id,
                viewModel: {
                  id: topic.reference_id,
                  name: topic.name,
                },
              })),
            );
          }

          return {
            id: chapter.reference_id,
            questionsCount: chapter.questions_count,
            topicsCount: chapter.topics_count,
            viewModel: {
              id: chapter.reference_id,
              name: chapter.name,
            },
          };
        }),
      );

      return {
        id: item.reference_id,
        viewModel: {
          id: item.reference_id,
          name: item.name,
          icon: item.metadata.icon,
          color: item.metadata.color,
          altText: item.metadata.altText,
        },
      };
    }) ?? [];

  // Build syllabus structure
  const subjectContentMap = new Map();
  res.subjects?.forEach((subject, subIdx) => {
    const chapterContentMap = new Map();

    res.chapters?.[subject.reference_id]?.forEach((chapter, chapIdx) => {
      const topicContentMap = new Map();

      res.topics?.[chapter.reference_id]?.forEach(topic => {
        topicContentMap.set(topic.reference_id, {
          id: topic.reference_id,
          viewModel: {
            id: topic.reference_id,
            name: topic.name,
          },
          isSelected: false,
        });
      });

      chapterContentMap.set(chapter.reference_id, {
        id: chapter.reference_id,
        viewModel: {
          id: chapter.reference_id,
          name: chapter.name,
          icon: chapter.metadata.icon,
          color: chapter.metadata.color,
        },
        isSelected: chapIdx === 0,
        questionsCount: chapter.questions_count,
        topicsCount: chapter.topics_count,
        topicsMap: Object.fromEntries(topicContentMap),
        selectedTopics: [],
      });
    });

    const firstChapterId =
      res.chapters?.[subject.reference_id]?.[0]?.reference_id;
    subjectContentMap.set(subject.reference_id, {
      id: subject.reference_id,
      viewModel: {
        id: subject.reference_id,
        name: subject.name,
        icon: subject.metadata.icon,
        color: subject.metadata.color,
        altName: subject.metadata.altText,
      },
      isSelected: subIdx === 0,
      chaptersMap: Object.fromEntries(chapterContentMap),
      selectedChapters: firstChapterId ? [firstChapterId] : [],
    });
  });

  return {
    syllabus: {
      subjectsMap: Object.fromEntries(subjectContentMap),
      selectedSubjects: res.subjects ? [res.subjects[0].reference_id] : [],
    },
    subjects,
    chapters: Object.fromEntries(chapters),
    topics: Object.fromEntries(topics),
    patterns:
      res.question_types?.map(item => ({
        id: item.reference_id,
        viewModel: {id: item.reference_id, name: item.name},
      })) ?? [],
    difficulty:
      res.difficulty_types?.map(item => {
        const type = stringToProblemDifficultyType(item.reference_id);
        return {
          id: item.reference_id,
          viewModel: {
            id: type,
            name: item.name,
            color: difficultyTheme[type],
          },
        };
      }) ?? [],
    attemptStatus:
      res.user_question_status?.map(item => {
        const type = stringToAttemptStatusType(item.reference_id);
        return {
          id: item.reference_id,
          viewModel: {
            id: type,
            name: item.name,
            color: statusTheme[type],
            icon: statusIcons[type] ?? undefined,
          },
        };
      }) ?? [],
    pyqMock: {
      years:
        res.previous_years?.map((item, idx) => ({
          ...item,
          isSelected: idx === 0,
        })) ?? [],
    },
  };
}

export const examConfigApiSlice = createApi({
  reducerPath: "examConfigApi",
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    getExamConfig: builder.query<TransformedConfigResponse, void>({
      query: () => ({
        url: "/core/content/",
        method: "GET",
      }),
      transformResponse: (response: ConfigResponse) =>
        transformConfigResponse(response),
    }),
  }),
});

export const {useGetExamConfigQuery} = examConfigApiSlice;
export type {TransformedConfigResponse};
