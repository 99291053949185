import {Avatar, Box, Stack, Typography} from "@mui/material";
import {useTimer} from "react-timer-hook";

function formattedTime(time: number) {
  return time < 10 ? `0${time}` : time;
}

export default function NavbarUI() {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 600);
  const {seconds, minutes, hours, isRunning, start, pause, resume, restart} =
    useTimer({
      expiryTimestamp,
      onExpire: () => console.warn("onExpire called"),
    });

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box sx={{width: "60px", p: "12px"}}>
        <Avatar
          alt="Prep Genie"
          src={process.env.PUBLIC_URL + `/PrepGenieLogo.svg`}
          variant="square"
          sx={{
            width: "100%",
            height: "auto",
            m: "auto",
          }}
        />
      </Box>
      <Typography
        variant="body1"
        fontWeight={600}
        color="secondary.contrastText">
        {formattedTime(hours)} : {formattedTime(minutes)} :{" "}
        {formattedTime(seconds)}
      </Typography>
    </Stack>
  );
}
