import {SxProps, Theme} from "@mui/material";

const TableBodyRowBorderRadius = "12px";

export const STYLES = {
  tableContainer: {
    overflow: "visible",
  } as SxProps<Theme>,
  table: {
    minWidth: "700px",
    borderCollapse: "separate",
    borderSpacing: "0px 8px",
  } as SxProps<Theme>,
  tableHeadRow: {
    th: {py: 1, borderBottom: 0},
  } as SxProps<Theme>,
  tableHeadCellWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: 0.5,
    justifyContent: "center",
  } as SxProps<Theme>,
  tableHeadTypography: {
    fontSize: "0.75rem",
    textTransform: "uppercase",
    fontWeight: 500,
    p: 0,
  } as SxProps<Theme>,
  tableBodyRow: (isLoading: boolean) =>
    ({
      borderRadius: TableBodyRowBorderRadius,
      backgroundColor: "primary.lighter",
      opacity: isLoading ? 0.65 : 1,
      cursor: isLoading ? "not-allowed" : "pointer",
      "td:first-of-type": {
        borderLeft: "1px solid #EAECF0",
        borderTopLeftRadius: TableBodyRowBorderRadius,
        borderBottomLeftRadius: TableBodyRowBorderRadius,
      },
      "td:last-of-type": {
        borderRight: "1px solid #EAECF0",
        borderTopRightRadius: TableBodyRowBorderRadius,
        borderBottomRightRadius: TableBodyRowBorderRadius,
      },
      td: {
        py: 1.25,
        borderTop: "1px solid #EAECF0",
        borderBottom: "1px solid #EAECF0",
      },
      ...(!isLoading && {
        "&:hover .cta-arrow-icon": {
          transform: "translateX(8px)",
        },
      }),
    }) as SxProps<Theme>,
  tableBodyRowSkeleton: {
    td: {p: 0, border: "none"},
  },
  tableFooterButtonTypography: {
    my: "2px",
    mx: "4px",
    fontSize: "0.875rem",
  } as SxProps<Theme>,
};
