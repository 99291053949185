import {
  mockTestCategoryDetails,
  MockTestCategoryType,
} from "./config/mock-test-config";
import CategoryItemUI from "./components/CategoryItemUI";
import {Stack} from "@mui/material";
import {useExamPageNavigate} from "../../pages/exam/common/navigation/useExamPageNavigate";
import {ExamPageSection} from "../../pages/exam/common/navigation/routes";
import {useGetMockTestCategory} from "./config/useGetMockTestCategory";
import {getMockTestCategoryItemList} from "./config/getMockTestCategoryItemList";

export default function MockTestCategoryFeature() {
  const {mockTestCategoryType} = useGetMockTestCategory();
  const data = getMockTestCategoryItemList(mockTestCategoryType);

  const examPageNavigate = useExamPageNavigate();
  const onCategoryItemClick = (id: MockTestCategoryType) => {
    examPageNavigate(ExamPageSection.MOCK_TEST_EXPLORE_WITH_CATEGORY, {
      mockTestCategoryId: mockTestCategoryDetails[id].routeId,
    });
  };

  return (
    <Stack direction="row" spacing={1.5}>
      {data.map(item => (
        <CategoryItemUI
          key={item.id}
          {...item}
          onClick={id => onCategoryItemClick(id)}
        />
      ))}
    </Stack>
  );
}
