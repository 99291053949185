import React, {createContext, useContext, useState, useCallback} from "react";
import {
  useEndQuestionMutation,
  useGetExamQuery,
  useViewQuestionMutation,
} from "../store/api/attemptExamDataApiSlice";
import {QuestionsDataType} from "../../../api/attempt-exam/get-exam";

interface PageContextType {
  currentQuestionData: QuestionsDataType | undefined;
  examId: string;
  // Pagination
  qNumber: number;
  setQNumber: (newQNumber: number) => void;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
}

const AttemptExamContext = createContext<PageContextType | undefined>(
  undefined,
);

interface AttemptExamProviderProps {
  maxPage: number;
  examId: string;
  children: React.ReactNode;
}

export const AttemptExamProvider: React.FC<AttemptExamProviderProps> = ({
  maxPage,
  examId,
  children,
}) => {
  const {data} = useGetExamQuery({examId});
  const [viewQuestion] = useViewQuestionMutation();
  const [endQuestion] = useEndQuestionMutation();

  const [qNumber, setQNumber] = useState<number>(1);
  const currentQuestionData = data?.questions[qNumber - 1];

  const setPage = useCallback(
    (newPage: number) => {
      const isValidatedPage = newPage >= 1 && newPage <= maxPage;
      if (isValidatedPage) {
        // end currentQuestion
        if (currentQuestionData) {
          endQuestion({
            examId,
            questionData: currentQuestionData,
          });
        }

        setQNumber(newPage);

        // view newQuestion
        const newQuestionData = data?.questions[newPage - 1];
        if (newQuestionData) {
          viewQuestion({
            examId,
            questionData: newQuestionData,
          });
        }
      }
    },
    [maxPage, examId, qNumber, data],
  );

  const isPreviousDisabled = qNumber === 1;
  const isNextDisabled = qNumber === maxPage;

  return (
    <AttemptExamContext.Provider
      value={{
        examId,
        currentQuestionData,
        qNumber,
        setQNumber: setPage,
        isNextDisabled,
        isPreviousDisabled,
      }}>
      {children}
    </AttemptExamContext.Provider>
  );
};

export const usePage = (): PageContextType => {
  const context = useContext(AttemptExamContext);
  if (!context) {
    throw new Error("usePage must be used within a PageProvider");
  }
  return context;
};
