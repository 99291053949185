import {TextField} from "@mui/material";
import {QuestionsDataType} from "../../../../api/attempt-exam/get-exam";

interface ProblemInputUIProps {
  question: QuestionsDataType;
  onChange: (newValue: string) => void;
}
export function ProblemInputUI({question, onChange}: ProblemInputUIProps) {
  return (
    <TextField
      id="test-subjective-answer"
      value={question.userResponse}
      onChange={e => onChange(e.target.value)}
      variant="outlined"
      placeholder="Enter your answer here"
      helperText={"Numerics only accepted"}
      autoFocus
      fullWidth
      sx={{
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "primary.dark",
            borderWidth: "1.5px",
          },
        },
      }}
    />
  );
}
