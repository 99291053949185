import {Box, CircularProgress} from "@mui/material";

export default function FullScreenLoaderSpinner() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100vh",
        width: "100vw",
        bgcolor: "#00000030",
        // backdropFilter: "blur(4px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}>
      <CircularProgress sx={{color: "black"}} />
    </Box>
  );
}
