import {Box, Button, IconButton, Paper, Stack, Typography} from "@mui/material";
import {MaterialSymbol, SymbolCodepoints} from "react-material-symbols";
import {useNavigate} from "react-router-dom";
import {STYLES} from "./styles";
import {useStartMockTestMutation} from "../../../../../../features/mock-test-table/store/api/mockTestApiSlice";
import FullScreenLoaderSpinner from "../../../../../../components/full-screen-loader-spinner";
import {ButtonViewModel} from "../../../../../../config/type";

export type MockTestDetailsOverviewViewModel = {
  title: string;
  subTitle: string;
  tags: {
    icon: SymbolCodepoints;
    label: string;
  }[];
  primaryButton: {
    viewModel: ButtonViewModel;
  };
};
interface MockTestDetailsOverviewUIProps {
  testId: string;
  viewModel?: MockTestDetailsOverviewViewModel;
}

export function MockTestDetailsOverviewUI({
  testId,
  viewModel,
}: MockTestDetailsOverviewUIProps) {
  const navigate = useNavigate();
  const [startMockTest, {isLoading}] = useStartMockTestMutation();

  const onTakeTestButtonClick = async () => {
    try {
      await startMockTest({testId: testId}).unwrap();
      navigate(`/test/attempt/${testId}`);
    } catch (error) {
      console.error("Failed to start test:", error);
    }
  };

  const primaryBtnViewModel = viewModel?.primaryButton.viewModel;

  return (
    <>
      {isLoading && <FullScreenLoaderSpinner />}
      <Paper variant="feature" sx={STYLES.paper}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <IconButton onClick={() => navigate(-1)}>
            <MaterialSymbol
              icon="arrow_back"
              size={22}
              weight={300}
              color="inherit"
            />
          </IconButton>
          <Typography
            component="p"
            variant="body2"
            fontWeight="400"
            color="secondary.contrastText">
            Go back
          </Typography>
        </Stack>

        <Box height={20} />

        <Stack sx={{px: 5}}>
          <Typography
            component="p"
            variant="body2"
            fontWeight="500"
            color="secondary.contrastText">
            {viewModel?.subTitle}
          </Typography>

          <Box height={8} />

          <Typography component="h5" variant="h5" fontWeight="700">
            {viewModel?.title}
          </Typography>

          <Box height={20} />

          <Stack direction="row" alignItems="center" spacing={4}>
            {viewModel?.tags.map((tag, tagIdx) => (
              <Stack
                key={tagIdx}
                direction="row"
                alignItems="center"
                spacing={1}>
                <Box sx={STYLES.tagIconBox}>
                  <MaterialSymbol icon={tag.icon} size={16} weight={500} />
                </Box>
                <Typography component="p" variant="body2" fontWeight="400">
                  {tag.label}
                </Typography>
              </Stack>
            ))}
          </Stack>

          <Box height={48} />

          <Box>
            <Button
              variant="contained"
              size="large"
              disabled={isLoading}
              disableElevation
              startIcon={
                primaryBtnViewModel?.icon && (
                  <MaterialSymbol
                    icon={primaryBtnViewModel?.icon.icon}
                    fill={primaryBtnViewModel?.icon.fill}
                  />
                )
              }
              sx={{
                ...STYLES.takeTestButton,
                bgcolor: primaryBtnViewModel?.styles.backgroundColor,
              }}
              onClick={onTakeTestButtonClick}>
              <Typography component="p" variant="body1" fontWeight="600">
                {primaryBtnViewModel?.label.name}
              </Typography>
            </Button>
          </Box>
        </Stack>

        <Box height={32} />
        <Box sx={STYLES.illustrationBox} />
      </Paper>
    </>
  );
}
