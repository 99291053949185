import {SimplePaletteColorOptions} from "@mui/material";
import {ProblemDifficultyType} from "../enums";

type DifficultyColorOptions = {
  [ProblemDifficultyType.Easy]: SimplePaletteColorOptions;
  [ProblemDifficultyType.Medium]: SimplePaletteColorOptions;
  [ProblemDifficultyType.Hard]: SimplePaletteColorOptions;
};

export const difficultyTheme: DifficultyColorOptions = {
  [ProblemDifficultyType.Easy]: {
    main: "#027A48",
  },
  [ProblemDifficultyType.Medium]: {
    main: "#FFB800",
  },
  [ProblemDifficultyType.Hard]: {
    main: "#D91A1A",
  },
};
