import {TableContainer, Table, Box, Stack} from "@mui/material";
import {STYLES} from "./styles";
import TableHeaderUI, {TableHeaderDataInterface} from "./TableHeaderUI";
import TableBodyUI, {TableRowDataInterface} from "./TableBodyUI";
import TableFooterUI, {TableFooterPaginationInterface} from "./TableFooterUI";
import TableSkeletonUI, {TableBodySkeletonUI} from "./TableSkeletonUI";
import TableEmptyStateUI from "./TableEmptyStateUI";

export type TableContentInterface = {
  isLoading: boolean;
  rowsData: TableRowDataInterface[] | null;
  headerData: TableHeaderDataInterface[] | null;
  pagination: TableFooterPaginationInterface;
  onRowClick: (rowId: string) => void;
};

type TableUIProps = TableContentInterface;

export default function TableUI({
  isLoading,
  headerData,
  rowsData,
  pagination,
  onRowClick,
}: TableUIProps) {
  return headerData === null ? (
    <TableSkeletonUI />
  ) : (
    <Stack flexGrow={1}>
      <TableContainer sx={STYLES.tableContainer}>
        <Table sx={STYLES.table} aria-label="table">
          <TableHeaderUI headerData={headerData} />
          {rowsData === null ? (
            <TableBodySkeletonUI numberOfColumns={headerData.length} />
          ) : (
            <TableBodyUI
              isLoading={isLoading}
              rows={rowsData}
              onRowClick={onRowClick}
            />
          )}
        </Table>
      </TableContainer>
      {rowsData !== null && rowsData.length === 0 && <TableEmptyStateUI />}
      <Box height={"16px"} />
      <TableFooterUI pagination={pagination} />
    </Stack>
  );
}
