import Grid from "@mui/material/Grid2";
import {Button, Skeleton, Stack, Typography} from "@mui/material";
import {STYLES} from "./styles";
import {useExamPageNavigate} from "../../../../../pages/exam/common/navigation/useExamPageNavigate";
import {ExamPageSection} from "../../../../../pages/exam/common/navigation/routes";
import {useGetMockTestCategory} from "../../../../mock-test-category/config/useGetMockTestCategory";
import {mockTestCategoryDetails} from "../../../../mock-test-category/config/mock-test-config";
import {useGetExamConfigQuery} from "../../../../../store/api/examConfigApiSlice";

export type PyqMockDropdownYearListType = {
  id: string;
  label: string;
  description: string;
  isSelected: boolean;
};

export default function PyqMockDropdownUI() {
  // Fetching the year list from the exam config
  const {data, isLoading} = useGetExamConfigQuery();
  const yearList = data?.pyqMock.years ?? [];

  // Navigating to the exam page with the selected year
  const navigateToExam = useExamPageNavigate();
  const {mockTestCategoryType} = useGetMockTestCategory();
  const onYearSelectClick = (id: string) => {
    navigateToExam(
      ExamPageSection.MOCK_TEST_EXPLORE_WITH_CATEGORY,
      {
        mockTestCategoryId:
          mockTestCategoryDetails[mockTestCategoryType].routeId,
      },
      {year: id},
    );
  };

  return (
    <Stack spacing={"12px"}>
      <Typography sx={STYLES.titleTypography}>Which year?</Typography>

      <Grid container sx={STYLES.yearGrid} spacing={1}>
        {isLoading ? (
          <YearListSkeletonUI />
        ) : (
          yearList.map(item => (
            <Grid size={4} key={item.label}>
              <Button
                onClick={() => onYearSelectClick(item.id)}
                variant={item.isSelected ? "contained" : "outlined"}
                fullWidth
                sx={STYLES.yearButton}>
                <Stack>
                  <Typography
                    fontWeight={700}
                    fontSize={"1rem"}
                    sx={STYLES.yearButtonText}>
                    {item.label}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={"0.75rem"}
                    sx={STYLES.yearButtonText}>
                    {item.description}
                  </Typography>
                </Stack>
              </Button>
            </Grid>
          ))
        )}
      </Grid>
    </Stack>
  );
}

function YearListSkeletonUI() {
  return (
    <>
      {Array.from({length: 9}).map((_, idx) => (
        <Grid size={4} key={idx}>
          <Skeleton variant="rectangular" sx={STYLES.yearSkeleton} />
        </Grid>
      ))}
    </>
  );
}
