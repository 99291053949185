import {SxProps, Theme} from "@mui/material";
import {strokeTheme} from "./../../../../config/themes/theme";

export const STYLES = {
  searchPaper: {
    p: "0px 4px",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    height: "40px",
    width: "54%",
    maxWidth: "560px",
    minWidth: "400px",
    border: strokeTheme,
  } as SxProps<Theme>,
  searchIconButton: {
    ml: "auto",
    mr: "12px",
    p: 0,
    color: "#757575",
    cursor: "default",
    ":hover": {
      bgcolor: "transparent",
    },
  } as SxProps<Theme>,
  searchSvgIcon: {
    fontSize: "1.25rem",
  } as SxProps<Theme>,
  slotInputProps: {
    width: "100%",
    color: "primary.contrastText",
    p: 0,
    "::placeholder": {
      color: "primary.contrastText",
      fontSize: "0.875rem",
    },
  } as SxProps<Theme>,

  // Styles for the subject button
  subjectButton: {
    height: "32px",
    // width: "200px!important",
    minWidth: "108px",
    maxWidth: "200px",
    borderRadius: "16px",
    display: "flex",
  } as SxProps<Theme>,
  subjectButtonTypography: {
    mr: "auto",
    ml: "2px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as SxProps<Theme>,
};
