import {Box, Fade} from "@mui/material";

type FullScreenOverlayProps = {
  onClick: (event: Event | React.SyntheticEvent) => void;
  open: boolean;
};
export default function FullScreenOverlay({
  onClick,
  open,
}: FullScreenOverlayProps) {
  return (
    <Fade in={open}>
      <Box
        onClick={onClick}
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 5,
        }}
      />
    </Fade>
  );
}
