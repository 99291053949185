import {Stack, SxProps, TableCell, Typography} from "@mui/material";
import {MaterialSymbol, SymbolCodepoints} from "react-material-symbols";
import TableCellChipUI from "./TableRowChipUI";

export enum TableCellViewType {
  TITLE_WITH_TAGS = "TITLE_WITH_TAGS",
  TEXT = "TEXT",
  ICON = "ICON",
  CTA = "CTA",
  EMPTY = "EMPTY",
}
export type TableCellInterface =
  | {
      label: {name: string; styles?: SxProps};
      tags: {
        name: string;
        icon?: SymbolCodepoints;
        color?: {main: string; contrastText: string};
      }[];
      cellType: TableCellViewType.TITLE_WITH_TAGS;
    }
  | {
      label: {
        name: string;
        color?: {main: string};
      };
      cellType: TableCellViewType.TEXT;
    }
  | {
      icon: SymbolCodepoints;
      color?: {main: string};
      cellType: TableCellViewType.ICON;
    }
  | {
      label: {name: string};
      cellType: TableCellViewType.CTA;
    }
  | {
      cellType: TableCellViewType.EMPTY;
    };

interface TableCellUIProps {
  data: TableCellInterface;
}
export default function TableCellUI({data}: TableCellUIProps) {
  switch (data.cellType) {
    case TableCellViewType.TEXT:
      return (
        <TableCell align="center">
          <Typography
            component="p"
            variant="body2"
            fontWeight={400}
            color={data.label.color?.main}>
            {data.label.name}
          </Typography>
        </TableCell>
      );
    case TableCellViewType.ICON:
      return (
        <TableCell align="center">
          <MaterialSymbol
            icon={data.icon}
            weight={300}
            size={24}
            color={data.color?.main}
          />
        </TableCell>
      );
    case TableCellViewType.TITLE_WITH_TAGS:
      return (
        <TableCell sx={{pl: 2.5}}>
          <Stack direction={"row"} spacing={2}>
            <Stack direction="column" spacing={1}>
              <Typography
                component="p"
                variant="body2"
                fontWeight={400}
                sx={data.label.styles}>
                {data.label.name}
              </Typography>
              <Stack direction="row" spacing={1}>
                {data.tags.map(tag => (
                  <TableCellChipUI
                    key={tag.name}
                    tag={{id: tag.name, ...tag}}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </TableCell>
      );
    case TableCellViewType.CTA:
      return (
        <TableCell align="right">
          <Stack
            direction={"row"}
            spacing={1.25}
            width={"100%"}
            justifyContent={"flex-end"}
            alignItems={"center"}>
            <Typography component="p" variant="body2" fontWeight={400}>
              {data.label.name}
            </Typography>
            <MaterialSymbol
              className="cta-arrow-icon"
              icon={"arrow_forward_ios"}
              weight={300}
              size={14}
              style={{transition: "transform 0.2s ease-in-out"}}
            />
          </Stack>
        </TableCell>
      );
    default:
      return <TableCell align="center"></TableCell>;
  }
}
