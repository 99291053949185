import {MaterialSymbol} from "react-material-symbols";
import {ViewModelProps} from "../../config/type";
import {Chip, Typography} from "@mui/material";

interface TableRowChipUIProps {
  tag: ViewModelProps;
}
export default function TableCellChipUI({tag}: TableRowChipUIProps) {
  return (
    <Chip
      icon={
        tag.icon ? (
          <MaterialSymbol
            icon={tag.icon}
            weight={300}
            size={16}
            style={{marginLeft: "6px", marginRight: "-6px"}}
          />
        ) : undefined
      }
      label={
        <Typography
          component="p"
          variant="caption"
          sx={{color: tag.color?.contrastText ?? "secondary.contrastText"}}>
          {tag.name}
        </Typography>
      }
      size="small"
      sx={{
        height: "24px",
        borderRadius: "20px",
        bgcolor: tag.color?.main ?? "default",
      }}
    />
  );
}
