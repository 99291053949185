import {Box, Card, CardActionArea, Stack, Typography} from "@mui/material";
import {ViewModelProps} from "../../../config/type";
import {MockTestCategoryType} from "../config/mock-test-config";

export type CategoryItemUIType = {
  id: MockTestCategoryType;
  isSelected: boolean;
  viewModel: ViewModelProps;
};
interface CategoryItemUIProps extends CategoryItemUIType {
  onClick: (id: MockTestCategoryType) => void;
}
export default function CategoryItemUI({
  isSelected,
  viewModel,
  onClick,
}: CategoryItemUIProps) {
  return (
    <Card sx={{borderRadius: "12px"}}>
      <CardActionArea
        data-active={isSelected ? "" : undefined}
        onClick={() => onClick(viewModel.id as MockTestCategoryType)}
        sx={{
          pl: "16px",
          pr: "24px",
          pt: "16px",
          pb: "16px",
          "&[data-active]": {
            bgcolor: viewModel.color?.main,
            color: viewModel.color?.dark,
            "&:hover": {
              bgcolor: viewModel.color?.main,
            },
          },
        }}>
        <Stack direction={"row"} alignItems={"center"} spacing={"12px"}>
          <Box
            width={"52px"}
            height={"52px"}
            sx={{
              mixBlendMode: isSelected ? null : "luminosity",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${viewModel.image})`,
            }}
          />
          <Stack spacing={"2px"}>
            <Typography fontSize={"0.875rem"} fontWeight={800}>
              {viewModel.name}
            </Typography>
            <Typography
              fontSize={"0.75rem"}
              fontWeight={400}
              whiteSpace="pre-wrap">
              {viewModel.description}
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
}
