import {Box, Button, Paper, Stack, TextField, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {MaterialSymbol} from "react-material-symbols";
import {usePage} from "../../hooks/AttemptExamProvider";
import {ProblemType} from "../../../../config/enums";
import ProblemOptionUI, {OptionStateType} from "./ProblemOptionUI";
import {
  useGetExamQuery,
  useUpdateQuestionUserResponseMutation,
} from "../../store/api/attemptExamDataApiSlice";
import {ProblemInputUI} from "./ProblemInputUI";

const HtmlToReactParser = require("html-to-react").Parser;

interface QuestionPanelMainProps {
  examId: string;
}
export function QuestionPanelMain({examId}: QuestionPanelMainProps) {
  const htmlToReactParser = new HtmlToReactParser();
  const {qNumber} = usePage();

  const {data} = useGetExamQuery({examId});
  const question = data?.questions[qNumber - 1];

  const [updateUserResponse] = useUpdateQuestionUserResponseMutation();
  const onUserResponseChange = (userResponse: string) => {
    updateUserResponse({
      examId,
      questionNumber: qNumber,
      userResponse: userResponse,
    });
  };

  function UserResponseUI() {
    switch (question?.qType) {
      case ProblemType.SingleCorrect:
        return (
          <Grid container spacing={"16px"}>
            {question.options.map((option, index) => (
              <Grid key={option.optId} size={6}>
                <ProblemOptionUI
                  data={htmlToReactParser.parse(option.optDescription)}
                  optionIdx={String.fromCharCode(65 + index)}
                  type={
                    option.optId === question.userResponse
                      ? OptionStateType.Selected
                      : OptionStateType.Default
                  }
                  onClick={() => onUserResponseChange(option.optId)}
                />
              </Grid>
            ))}
          </Grid>
        );
      case ProblemType.Numerical:
        return (
          <Paper variant="feature" sx={{py: 2, px: 2}}>
            <ProblemInputUI
              question={question}
              onChange={newValue => {
                if (!isNaN(Number(newValue))) {
                  onUserResponseChange(newValue);
                }
              }}
            />
          </Paper>
        );
      default:
        return <></>;
    }
  }

  return (
    <>
      <Paper variant="feature" sx={{p: 3}}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Stack spacing={0.5}>
            <Typography component="p" variant="body1" fontWeight="600">
              Question {question?.qNumber}
            </Typography>
            <Typography
              component="p"
              variant="body2"
              fontWeight="600"
              color="secondary.contrastText">
              {question?.qMarkingScheme}
            </Typography>
          </Stack>
          <Button
            variant="text"
            startIcon={<MaterialSymbol icon="flag" size={16} />}>
            Report
          </Button>
        </Stack>

        <Box height={24} />

        <Typography color="primary.contrastText">
          {htmlToReactParser.parse(question?.qDescription)}
        </Typography>
      </Paper>
      <Box width={"100%"}>
        <UserResponseUI />
      </Box>
    </>
  );
}
