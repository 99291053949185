import {useNavigate, useParams} from "react-router-dom";
import {ExamPageSection, EXAM_PAGE_ROUTES as ROUTES} from "./routes";

type RouteParams = {
  [ExamPageSection.PROBLEM_SET]: undefined;
  [ExamPageSection.PROBLEM_SET_SOLVE]: {problemId: string};
  [ExamPageSection.MOCK_TEST_INSTRUCTIONS]: {testId: string};
  [ExamPageSection.PLANNER]: undefined;
  [ExamPageSection.MOCK_TEST_EXPLORE]: undefined;
  [ExamPageSection.MOCK_TEST_EXPLORE_WITH_CATEGORY]: {
    mockTestCategoryId: string;
  };
};

export const useExamPageNavigate = () => {
  const {examPath} = useParams<{examPath: string}>();
  const navigate = useNavigate();

  return <T extends keyof RouteParams>(
    route: T,
    params: RouteParams[T],
    queryParams?: Record<string, string>,
    replace?: boolean,
  ) => {
    let path = ROUTES[route];

    // Replace dynamic params in the route
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        path = path.replace(`:${key}`, value);
      });
    }

    // Add query params if provided
    if (queryParams) {
      const searchParams = new URLSearchParams(queryParams).toString();
      path += `?${searchParams}`;
    }

    navigate(`/${examPath}/` + path, {replace});
  };
};
