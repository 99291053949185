import {Menu} from "@mui/material";
import PyqMockDropdownUI from "./PyqMockDropdownUI";
import FullScreenOverlay from "../../../../../components/full-screen-overlay";

interface SearchButtonDropdownMainProps {
  anchorRef: React.RefObject<HTMLButtonElement>;
  openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
export default function SearchButtonDropdownMain({
  anchorRef,
  openState: [isButtonDropdownOpen, setIsButtonDropdownOpen],
}: SearchButtonDropdownMainProps) {
  const handleDropdownClose = () => {
    setIsButtonDropdownOpen(false);
  };
  return (
    <>
      <FullScreenOverlay
        open={isButtonDropdownOpen}
        onClick={handleDropdownClose}
      />
      <Menu
        id="search-button-dropdown"
        anchorEl={anchorRef.current}
        open={isButtonDropdownOpen}
        onClose={handleDropdownClose}
        slotProps={{
          root: {sx: {mt: 1, ".MuiList-root": {p: 0}}},
          paper: {
            sx: {
              borderRadius: "12px",
              width: "400px",
              py: "20px",
              maxHeight: "60vh",
              overflow: "scroll",
              position: "relative",
            },
          },
        }}>
        <PyqMockDropdownUI />
      </Menu>
    </>
  );
}
