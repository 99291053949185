import {createSlice} from "@reduxjs/toolkit";
import {SearchButtonLabelViewModelType} from "../../components/search/SearchButtonUI";
import {MockTestCategoryType} from "../../../mock-test-category/config/mock-test-config";

type StateType = {
  searchData: {
    buttonLabelViewModel: SearchButtonLabelViewModelType | undefined;
  };
};

const initialState: StateType = {
  searchData: {
    buttonLabelViewModel: undefined,
  },
};

export const SearchDataSlice = createSlice({
  name: "searchData",
  initialState,
  reducers: {
    setSearchButtonLabel(
      state,
      action: {payload: SearchButtonLabelViewModelType | undefined},
    ) {
      state.searchData.buttonLabelViewModel = action.payload;
    },
    setSearchButtonLabelWhenMockTestCategoryChanged(
      state,
      action: {payload: {id: MockTestCategoryType}},
    ) {
      const selectedCategory = action.payload.id;
      switch (selectedCategory) {
        default: {
          state.searchData.buttonLabelViewModel = undefined;
        }
      }
    },
  },
});

// {
//   id: selectedYear.id,
//   name: selectedYear.label,
//   icon: "event",
// }

export const {
  setSearchButtonLabel,
  setSearchButtonLabelWhenMockTestCategoryChanged,
} = SearchDataSlice.actions;
export default SearchDataSlice.reducer;
