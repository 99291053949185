import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../../../store/axiosBaseQuery";
import {
  convertGetExamResponse,
  ExamDataType,
  QuestionsDataType,
} from "../../../../api/attempt-exam/get-exam";
import {ExamQuestionStatusType} from "../../components/right-panel/QuestionNavigationButtonUI";

enum AnalyticsType {
  QUESTION_VIEW = "QUESTION_VIEW",
  QUESTION_ATTEMPT = "QUESTION_ATTEMPT",
}
type AnalyticsPayload = {
  reference_id: string;
} & (
  | {start_datetime: string; type: AnalyticsType.QUESTION_VIEW}
  | {
      end_datetime: string;
      response_data: string | null;
      is_bookmarked: boolean;
      type: AnalyticsType.QUESTION_ATTEMPT;
    }
);

export const attemptExamDataApiSlice = createApi({
  reducerPath: "attemptExamDataApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["ExamData"],
  endpoints: builder => ({
    getExam: builder.query<ExamDataType, {examId: string}>({
      query: ({examId}) => ({
        url: `/test_series/exam_test/${examId}/questions`,
      }),
      transformResponse: response => {
        return convertGetExamResponse(response);
      },
      providesTags: ["ExamData"],
    }),
    updateQuestionUserResponse: builder.mutation<
      void,
      {examId: string; questionNumber: number; userResponse: string | null}
    >({
      queryFn: async ({examId, questionNumber, userResponse}, api) => {
        api.dispatch(
          attemptExamDataApiSlice.util.updateQueryData(
            "getExam",
            {examId},
            draft => {
              const question = draft.questions[questionNumber - 1];

              question.userResponse = userResponse;
              if (userResponse && userResponse != "")
                question.status = ExamQuestionStatusType.ANSWERED;
              else question.status = ExamQuestionStatusType.NOT_ANSWERED;
            },
          ),
        );
        return {data: undefined};
      },
    }),
    updateQuestionBookmark: builder.mutation<
      void,
      {examId: string; questionNumber: number; isBookmarked: boolean}
    >({
      queryFn: async ({examId, questionNumber, isBookmarked}, api) => {
        api.dispatch(
          attemptExamDataApiSlice.util.updateQueryData(
            "getExam",
            {examId},
            draft => {
              draft.questions[questionNumber - 1].isBookmarked = isBookmarked;
            },
          ),
        );
        return {data: undefined};
      },
    }),
    viewQuestion: builder.mutation<
      void,
      {
        examId: string;
        questionData: QuestionsDataType;
      }
    >({
      query: ({examId, questionData: {qId}}) => ({
        url: `/test_series/exam_test/${examId}/analytics/`,
        method: "POST",
        data: {
          analytics: {
            reference_id: qId,
            start_datetime: getCurrentTime(),
            type: AnalyticsType.QUESTION_VIEW,
          } as AnalyticsPayload,
        },
      }),
      async onQueryStarted(
        {examId, questionData: {qNumber: qNum}},
        {dispatch, queryFulfilled},
      ) {
        try {
          // Update the cache to mark question as viewed
          dispatch(
            attemptExamDataApiSlice.util.updateQueryData(
              "getExam",
              {examId},
              draft => {
                const question = draft.questions[qNum - 1];
                if (
                  question &&
                  question.status === ExamQuestionStatusType.NOT_VISITED
                ) {
                  question.status = ExamQuestionStatusType.NOT_ANSWERED;
                }
              },
            ),
          );

          // Wait for the actual API response
          await queryFulfilled;
        } catch {
          // If the API call fails, revert the optimistic update
          dispatch(attemptExamDataApiSlice.util.invalidateTags(["ExamData"]));
        }
      },
    }),
    endQuestion: builder.mutation<
      void,
      {
        examId: string;
        questionData: QuestionsDataType;
      }
    >({
      query: ({examId, questionData: {qId, isBookmarked, userResponse}}) => ({
        url: `/test_series/exam_test/${examId}/analytics/`,
        method: "POST",
        data: {
          analytics: {
            reference_id: qId,
            end_datetime: getCurrentTime(),
            response_data: userResponse,
            is_bookmarked: isBookmarked,
            type: AnalyticsType.QUESTION_ATTEMPT,
          } as AnalyticsPayload,
        },
      }),
    }),
    endTest: builder.mutation<
      void,
      {
        examId: string;
        questionData: QuestionsDataType;
      }
    >({
      query: ({examId, questionData: {qId, isBookmarked, userResponse}}) => ({
        url: `/test_series/exam_test/${examId}/analytics/`,
        method: "POST",
        data: {
          analytics: {
            reference_id: qId,
            end_datetime: getCurrentTime(),
            response_data: userResponse,
            is_bookmarked: isBookmarked,
            type: AnalyticsType.QUESTION_ATTEMPT,
          } as AnalyticsPayload,
          end_test: true,
        },
      }),
    }),
  }),
});

export const {
  useGetExamQuery,
  useUpdateQuestionUserResponseMutation,
  useUpdateQuestionBookmarkMutation,
  useViewQuestionMutation,
  useEndQuestionMutation,
  useEndTestMutation,
} = attemptExamDataApiSlice;

function getCurrentTime() {
  return new Date().toISOString().slice(0, 19).replace("T", " ");
}
