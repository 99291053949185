import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../../../store/axiosBaseQuery";
import {convertGetTestsListResponseType} from "../../../../api/mock-test/get-tests-list";
import {TableRowDataInterface} from "../../../../components/table/TableBodyUI";
import {TableFooterPaginationInterface} from "../../../../components/table/TableFooterUI";
import {convertGetTestDetailsResponseType} from "../../../../api/mock-test/get-exam-detail";
import {MockTestDetailsProps} from "../../../../pages/exam/common/section/mock-test/MockTestDetailsPage";

export const mockTestApiSlice = createApi({
  reducerPath: "mockTestApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["MockTest"],
  endpoints: builder => ({
    getMockTestList: builder.query<
      {
        pagination: TableFooterPaginationInterface;
        rowsData: TableRowDataInterface[];
      },
      {mockTestCategoryId: string; year: string; page: string | null}
    >({
      query: ({mockTestCategoryId, year, page}) => ({
        url: `/test_series/exam_test/`,
        method: "GET",
        params: {
          tag_type: mockTestCategoryId,
          year: year,
          test_type: "S",
          page: page ?? 1,
        },
      }),
      transformResponse: response => {
        return convertGetTestsListResponseType(response);
      },
      providesTags: ["MockTest"],
    }),
    getMockTestDetails: builder.query<MockTestDetailsProps, {testId: string}>({
      query: ({testId}) => ({
        url: `/test_series/exam_test/${testId}/`,
        method: "GET",
      }),
      transformResponse: response => {
        return convertGetTestDetailsResponseType(response);
      },
      providesTags: ["MockTest"],
    }),
    startMockTest: builder.mutation<void, {testId: string}>({
      query: ({testId}) => ({
        url: `/test_series/exam_test/${testId}/start/`,
        method: "POST",
      }),
      invalidatesTags: ["MockTest"],
    }),
  }),
});

export const {
  useGetMockTestListQuery,
  useGetMockTestDetailsQuery,
  useStartMockTestMutation,
} = mockTestApiSlice;
