import {ViewModelProps} from "../../../config/type";

export enum MockTestCategoryType {
  PYQ = "P",
  CHAPTER_WISE = "CH",
  CUSTOM = "O",
}

export type MockTestDetailProps = {
  type: MockTestCategoryType;
  routeId: string;
  viewModel: ViewModelProps;
  isDisabled?: boolean;
};
export const mockTestCategoryDetails: Record<
  MockTestCategoryType,
  MockTestDetailProps
> = {
  [MockTestCategoryType.PYQ]: {
    type: MockTestCategoryType.PYQ,
    routeId: "pyqs",
    viewModel: {
      id: MockTestCategoryType.PYQ,
      name: "Previous Year Questions",
      description: "Practice past year papers in\na real exam-like environment",
      color: {
        main: "#D8F2E9",
        dark: "#21382E",
      },
      icon: "hourglass_bottom",
      image: "/mockTest/previous-year-papers.png",
    },
  },
  [MockTestCategoryType.CHAPTER_WISE]: {
    type: MockTestCategoryType.CHAPTER_WISE,
    routeId: "chapter-wise",
    viewModel: {
      id: MockTestCategoryType.CHAPTER_WISE,
      name: "Chapter-wise Mock",
      description: "Crushed too many questions?\nTry them under the clock now!",
      color: {
        main: "#FDD8D7",
        dark: "#8B2528",
      },
      icon: "auto_stories",
      image: "/mockTest/chapter-wise-mock.png",
    },
  },
  [MockTestCategoryType.CUSTOM]: {
    type: MockTestCategoryType.CUSTOM,
    routeId: "custom",
    viewModel: {
      id: MockTestCategoryType.CUSTOM,
      name: "Create Custom Mock",
      description: "Take an exam with a mix of\nsubjects and chapters",
      color: {
        main: "#FAE0FE",
        dark: "#64316C",
      },
      icon: "date_range",
      image: "/mockTest/create-custom-mock.png",
    },
  },
};
