import {TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {STYLES} from "./styles";

export type TableHeaderDataInterface = {
  label: string;
  widthPercentage: string;
  align: "left" | "center" | "right";
};
interface TableHeaderUIProps {
  headerData: TableHeaderDataInterface[];
}
export default function TableHeaderUI({headerData}: TableHeaderUIProps) {
  return (
    <>
      <colgroup>
        {headerData.map(column => (
          <col key={column.label} width={column.widthPercentage} />
        ))}
      </colgroup>
      <TableHead>
        <TableRow sx={STYLES.tableHeadRow}>
          {headerData.map(column => (
            <TableCell key={column.label} align={column.align}>
              <Typography component="p" sx={STYLES.tableHeadTypography}>
                {column.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}
