import {examConfigApiSlice} from "../examConfigApiSlice";
import {AppDispatch} from "../../store";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {FilterType} from "../../../config/enums";
import {useGetQueryParam} from "../../../utils/navigation/useQueryParam";

export const useUpdatePyqYearSelection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const yearId = useGetQueryParam(FilterType.YEAR);

  useEffect(() => {
    const patchResult = examConfigApiSlice.util.updateQueryData(
      "getExamConfig",
      undefined,
      draft => {
        if (!draft?.pyqMock?.years) return;
        if (yearId && !draft.pyqMock.years.some(year => year.id === yearId)) {
          throw new Error(`Year with id ${yearId} not found`);
        }

        draft.pyqMock.years = draft.pyqMock.years.map((year, idx) => ({
          ...year,
          isSelected: !yearId ? idx === 0 : year.id === yearId,
        }));
      },
    );

    dispatch(patchResult);
  }, [yearId, dispatch]);
};
