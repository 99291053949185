import {Box, Stack, Typography} from "@mui/material";

export enum EmptyStateBadgeType {
  NO_RECORDS,
  CUSTOM,
}

function getEmptyStateBgImage(
  emptyStateBadge: EmptyStateUIProps["emptyState"]["emptyStateBadge"],
): string {
  switch (emptyStateBadge.type) {
    case EmptyStateBadgeType.NO_RECORDS:
      return `url(/mockTest/empty-dashboard.png)`;
    case EmptyStateBadgeType.CUSTOM:
      return `url(${emptyStateBadge.url})`;
  }
}

interface EmptyStateUIProps {
  emptyState: {
    emptyStateBadge:
      | {type: EmptyStateBadgeType.NO_RECORDS}
      | {type: EmptyStateBadgeType.CUSTOM; url: string};
    title: string;
    description: string;
  };
}
export default function EmptyStateUI({emptyState}: EmptyStateUIProps) {
  return (
    <Box sx={{px: "36px", py: "32px"}}>
      <Stack>
        <Stack direction={"row"} justifyContent={"center"}>
          <Box
            width={"120px"}
            height={"120px"}
            sx={{
              mixBlendMode: "luminosity",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: getEmptyStateBgImage(emptyState.emptyStateBadge),
            }}
          />
        </Stack>
        <Box height={"16px"} />
        <Stack direction={"row"} justifyContent={"center"}>
          <Typography variant={"h6"} fontWeight={700} fontSize={"1.25rem"}>
            {emptyState.title}
          </Typography>
        </Stack>
        <Stack direction={"row"} justifyContent={"center"}>
          <Typography variant={"body1"} fontWeight={300} fontSize={"0.875rem"}>
            {emptyState.description}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
