export enum ExamPageSection {
  PROBLEM_SET,
  PROBLEM_SET_SOLVE,
  PLANNER,
  MOCK_TEST_EXPLORE_WITH_CATEGORY,
  MOCK_TEST_EXPLORE,
  MOCK_TEST_INSTRUCTIONS,
  //   MOCK_TEST_ATTEMPT,
  //   MOCK_TEST_ANALYSIS,
}

export const EXAM_PAGE_ROUTES: Record<ExamPageSection, string> = {
  [ExamPageSection.PROBLEM_SET]: "",
  [ExamPageSection.PROBLEM_SET_SOLVE]: "solve/:problemId",
  [ExamPageSection.PLANNER]: "planner",
  [ExamPageSection.MOCK_TEST_EXPLORE]: "mock-test",
  [ExamPageSection.MOCK_TEST_EXPLORE_WITH_CATEGORY]:
    "mock-test/:mockTestCategoryId",
  [ExamPageSection.MOCK_TEST_INSTRUCTIONS]: "mock-test/test-details/:testId",
  //   [ExamPageSection.MOCK_TEST_ATTEMPT]: "mock-test-attempt",
  //   [ExamPageSection.MOCK_TEST_ANALYSIS]: "mock-test-analysis",
};
