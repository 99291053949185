import {Button, IconButton, Stack, Typography} from "@mui/material";
import {MaterialSymbol} from "react-material-symbols";
import {useEndTestMutation} from "../../store/api/attemptExamDataApiSlice";
import {usePage} from "../../hooks/AttemptExamProvider";

type QuestionNavigationTopbarUIProps = {
  examName: string;
};
export default function QuestionNavigationTopbarUI({
  examName,
}: QuestionNavigationTopbarUIProps) {
  const {currentQuestionData: questionData, examId} = usePage();

  const [endTest] = useEndTestMutation();
  const handleEndTest = () => {
    if (questionData) endTest({examId, questionData});
    else throw "when ending test, question is undefined";
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <IconButton sx={{color: "secondary.contrastText"}}>
          <MaterialSymbol icon="info" size={20} weight={400} color="inherit" />
        </IconButton>
        <Typography
          variant="body2"
          fontWeight={500}
          color="secondary.contrastText">
          {examName}
        </Typography>
      </Stack>
      <Button
        variant="outlined"
        sx={{px: 2, py: 1, fontWeight: 600}}
        onClick={handleEndTest}
        startIcon={<MaterialSymbol icon="exit_to_app" size={16} />}>
        End test
      </Button>
    </Stack>
  );
}
