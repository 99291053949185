export const STYLES = {
  yearButton: {
    py: "12px",
  },
  yearButtonText: {
    textTransform: "none",
    textWrap: "nowrap",
    textOverflow: "ellipsis",
  },
  yearSkeleton: {
    height: "66px",
    borderRadius: "8px",
  },
  titleTypography: {
    px: "20px",
    component: "h6",
    variant: "body1",
    fontWeight: "700",
  },
  yearGrid: {
    px: "20px",
  },
} as const;
