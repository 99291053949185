import type {AxiosRequestConfig, AxiosError} from "axios";
import {instanceWithHeaders as axiosInstanceWithHeaders} from "../config/axios";

// Custom Base Query using Axios
const axiosBaseQuery =
  () =>
  async ({url, method = "GET", data, params}: AxiosRequestConfig) => {
    try {
      const result = await axiosInstanceWithHeaders({
        url: url,
        method,
        data,
        params,
      });
      return {data: result.data};
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;
