import {CategoryItemUIType} from "../components/CategoryItemUI";
import {
  mockTestCategoryDetails,
  MockTestCategoryType,
} from "./mock-test-config";

export const getMockTestCategoryItemList = (
  selectedId: MockTestCategoryType,
): CategoryItemUIType[] => {
  return Object.entries(mockTestCategoryDetails).map(([key, value]) => ({
    id: key as MockTestCategoryType,
    viewModel: value.viewModel,
    isSelected: key === selectedId,
  }));
};
