import {ProblemType, stringToProblemType} from "../../config/enums";
import {ExamQuestionStatusType} from "../../features/attempt-exam/components/right-panel/QuestionNavigationButtonUI";

interface OptionResponse {
  reference_id: string;
  description: string;
}

interface QuestionResponse {
  reference_id: string;
  question_number: number;
  subject?: string; // "Physics", "Chemistry", etc.
  marks: string;
  description: string;
  question_type: string; // SC, SU
  options?: OptionResponse[];
  response_data: string | null;
  status: ExamQuestionStatusType; // Not Visited, Answered, Not answered
  is_bookmarked: boolean;
}

interface ExamResponse {
  exam_test_reference_id: string;
  exam_test_name: string;
  end_time: string;
  questions: QuestionResponse[];
}

// Return Type
type OptionDataType = {
  optId: string;
  optDescription: string;
};
export type QuestionsDataType = {
  qId: string;
  qNumber: number;
  qSection: string;
  qMarkingScheme: string;
  qDescription: string;
  userResponse: string | null;
  status: ExamQuestionStatusType;
  isBookmarked: boolean;
} & (
  | {
      qType: ProblemType.SingleCorrect;
      options: OptionDataType[];
    }
  | {qType: ProblemType.Numerical | ProblemType.Unkown}
);

export type ExamDataType = {
  examId: string;
  examName: string;
  endTime: Date;
  questionsCount: number;
  questions: QuestionsDataType[];
};

export function convertGetExamResponse(response: ExamResponse): ExamDataType {
  return {
    examId: response.exam_test_reference_id,
    examName: response.exam_test_name,
    endTime: new Date(response.end_time),
    questionsCount: response.questions.length,
    questions: response.questions.map(question => {
      const qType = stringToProblemType(question.question_type);
      return {
        qId: question.reference_id,
        qNumber: question.question_number,
        qSection: question.subject ?? "",
        qMarkingScheme: question.marks,
        qDescription: question.description,
        userResponse: question.response_data,
        status: question.status,
        isBookmarked: question.is_bookmarked,
        ...(qType === ProblemType.SingleCorrect
          ? {
              qType: ProblemType.SingleCorrect,
              options:
                question.options?.map(option => {
                  return {
                    optId: option.reference_id,
                    optDescription: option.description,
                  };
                }) ?? [],
            }
          : {
              qType: qType,
            }),
      };
    }),
  };
}
