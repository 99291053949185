import {Box, Button} from "@mui/material";
import {MaterialSymbol} from "react-material-symbols";

export enum ExamQuestionStatusType {
  NOT_VISITED = "NOT_VISITED",
  NOT_ANSWERED = "NOT_ANSWERED",
  ANSWERED = "ANSWERED",
}

function getQuestionStatusColor(statusType: ExamQuestionStatusType) {
  switch (statusType) {
    case ExamQuestionStatusType.NOT_ANSWERED:
      return {
        light: "error.light",
        main: "error.main",
        borderColor: "error.light",
      };
    case ExamQuestionStatusType.ANSWERED:
      return {
        light: "success.light",
        main: "success.main",
        borderColor: "success.light",
      };
    default:
      return {
        light: "white",
        main: "secondary.contrastText",
        borderColor: undefined,
      };
  }
}

interface QuestionNavigationButtonUIProps {
  qNum: number;
  isSelected?: boolean;
  statusType?: ExamQuestionStatusType;
  isBookmark?: boolean;
  onClick: (qNum: number) => void;
}
export default function QuestionNavigationButtonUI({
  qNum,
  isSelected = false,
  statusType = ExamQuestionStatusType.NOT_VISITED,
  isBookmark = false,
  onClick,
}: QuestionNavigationButtonUIProps) {
  const btnSize = 50;
  const btnColor = getQuestionStatusColor(statusType);

  return (
    <Box sx={{position: "relative", width: btnSize, height: btnSize}}>
      <Button
        variant={"outlined"}
        sx={{
          p: 0,
          minWidth: btnSize,
          width: btnSize,
          height: btnSize,
          borderSize: isSelected ? 1.5 : 1,
          borderRadius: 1,
          borderColor: isSelected ? "black" : btnColor.borderColor,
          bgcolor: btnColor.light,
          color: isSelected ? "black" : btnColor.main,
          fontWeight: isSelected ? 800 : 400,
          "&:hover": {
            bgcolor: isSelected ? btnColor.light : "grey.200",
          },
        }}
        onClick={() => onClick(qNum)}>
        {qNum}
      </Button>

      {/* Bookmark Icon */}
      {isBookmark && (
        <MaterialSymbol
          icon="bookmark"
          size={24}
          style={{
            position: "absolute",
            bottom: -8,
            right: -12,
            color: "orange",
          }}
          fill
        />
      )}
    </Box>
  );
}
