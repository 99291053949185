import {Box, SimplePaletteColorOptions, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {MaterialSymbol, SymbolCodepoints} from "react-material-symbols";

interface OverviewProps {
  label: string;
  icon: SymbolCodepoints;
  qCount: number;
  color: SimplePaletteColorOptions;
}
interface ExamOverviewUIProps {
  notVisited: number;
  notAnswered: number;
  answered: number;
  bookmarked: number;
}
export default function ExamOverviewUI({
  answered,
  notAnswered,
  notVisited,
  bookmarked,
}: ExamOverviewUIProps) {
  const overviewList: OverviewProps[] = [
    {
      label: "Answered",
      icon: "square",
      qCount: answered,
      color: {
        light: "success.light",
        main: "success.main",
      },
    },
    {
      label: "Not Answered",
      icon: "square",
      qCount: notAnswered,
      color: {
        light: "error.light",
        main: "error.main",
      },
    },
    {
      label: "Not Visited",
      icon: "square",
      qCount: notVisited,
      color: {
        light: "secondary.light",
        main: "secondary.main",
      },
    },
    {
      label: "Marked for review",
      icon: "bookmark",
      qCount: bookmarked,
      color: {
        light: "warning.light",
        main: "warning.main",
      },
    },
  ];
  return (
    <Stack spacing={2} pl={1}>
      <Grid container spacing={2}>
        {overviewList.map((item, i) => (
          <Grid size={6} key={i + 1}>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <Box
                color={item.color.main}
                height={"100%"}
                display={"flex"}
                alignItems={"center"}>
                <MaterialSymbol icon={item.icon} size={32} fill />
              </Box>
              <Stack spacing={0}>
                <Typography variant="body1" fontWeight="600">
                  {item.qCount} Q&lsquo;s
                </Typography>
                <Typography variant="body2" fontWeight="400">
                  {item.label}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
