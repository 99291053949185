import {Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import QuestionNavigationButtonUI from "./QuestionNavigationButtonUI";
import {QuestionsDataType} from "../../../../api/attempt-exam/get-exam";
import {usePage} from "../../hooks/AttemptExamProvider";

interface QuestionNavigationUIProps {
  qSection: string;
  qList: QuestionsDataType[];
}
export default function QuestionNavigationUI({
  qSection,
  qList,
}: QuestionNavigationUIProps) {
  const {qNumber: page, setQNumber: setPage} = usePage();

  const handleQuestionClick = (qNum: number) => {
    setPage(qNum);
  };

  return (
    <Stack spacing={2} pl={1}>
      <Typography variant="body1" fontWeight="600">
        {qSection}
      </Typography>
      <Grid container spacing={2}>
        {qList.map((qItem, i) => (
          <Grid key={qItem.qId}>
            <QuestionNavigationButtonUI
              qNum={qItem.qNumber}
              isBookmark={qItem.isBookmarked}
              statusType={qItem.status}
              isSelected={page == qItem.qNumber}
              onClick={handleQuestionClick}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
