import {STYLES} from "./styles";
import {Box, Paper, TableBody, TableRow} from "@mui/material";
import TableCellUI, {TableCellInterface} from "./TableCellUI";

export type TableRowDataInterface = {
  id: string;
  data: TableCellInterface[];
};

interface TableBodyUIProps {
  rows: TableRowDataInterface[];
  onRowClick: (id: string) => void;
  isLoading: boolean;
}
export default function TableBodyUI({
  rows,
  onRowClick,
  isLoading,
}: TableBodyUIProps) {
  return (
    <TableBody>
      {rows.map(({id, data}) => (
        <TableRow
          key={id}
          component={Paper}
          sx={STYLES.tableBodyRow(isLoading)}
          onClick={() => !isLoading && onRowClick(id)}>
          {data.map((cell, cellIdx) => (
            <TableCellUI key={id + cellIdx} data={cell} />
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}
