import {MaterialSymbol, SymbolCodepoints} from "react-material-symbols";
import {STYLES} from "./styles";
import {
  Button,
  SimplePaletteColorOptions,
  Skeleton,
  Typography,
} from "@mui/material";

export type SearchButtonLabelViewModelType = {
  id: string;
  name: string;
  color?: SimplePaletteColorOptions;
  icon: SymbolCodepoints;
  endIcon?: SymbolCodepoints;
};
interface SearchButtonUIProps {
  onClick: () => void;
  labelViewModel?: SearchButtonLabelViewModelType;
}
export default function SearchButtonUI({
  onClick,
  labelViewModel,
}: SearchButtonUIProps) {
  return labelViewModel ? (
    <Button
      variant="contained"
      onClick={onClick}
      disableElevation
      startIcon={
        <MaterialSymbol
          icon={labelViewModel.icon ?? "title"}
          style={{fontSize: "1rem"}}
        />
      }
      endIcon={
        <MaterialSymbol icon={labelViewModel.endIcon ?? "arrow_drop_down"} />
      }
      sx={{
        ...STYLES.subjectButton,
        bgcolor: labelViewModel.color?.light,
        color: labelViewModel.color?.main,
      }}>
      <Typography variant="body2" sx={STYLES.subjectButtonTypography}>
        {labelViewModel.name}
      </Typography>
    </Button>
  ) : (
    <Skeleton variant="rounded" sx={STYLES.subjectButton} />
  );
}
