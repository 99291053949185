import {Box} from "@mui/material";
import EmptyStateUI, {EmptyStateBadgeType} from "../empty-state/EmptyStateUI";

export default function TableEmptyStateUI() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
      }}>
      <EmptyStateUI
        emptyState={{
          emptyStateBadge: {type: EmptyStateBadgeType.NO_RECORDS},
          title: "No tests yet",
          description:
            "No tests available at the moment. Check back later or create a new one.",
        }}
      />
    </Box>
  );
}
