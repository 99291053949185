import {useParams} from "react-router-dom";
import {
  mockTestCategoryDetails,
  MockTestCategoryType,
  MockTestDetailProps,
} from "./mock-test-config";

const getMockTestCategoryByRouteId = (
  routeId?: string,
): {isValid: boolean; mockTestCategoryType: MockTestCategoryType} => {
  const entries = Object.entries(mockTestCategoryDetails) as [
    MockTestCategoryType,
    MockTestDetailProps,
  ][];

  // Find the entry where the routeId matches
  const foundEntry = entries.find(([, details]) => details.routeId === routeId);

  // Return the found MockTestCategoryType, otherwise return the first type
  return foundEntry
    ? {isValid: true, mockTestCategoryType: foundEntry[0]}
    : {isValid: false, mockTestCategoryType: entries[0][0]};
};

export const useGetMockTestCategory = () => {
  const {mockTestCategoryId} = useParams<{mockTestCategoryId: string}>();

  // Get a valid category type based on routeId
  return getMockTestCategoryByRouteId(mockTestCategoryId);
};
