import getFiltersAPI from "../../../api/problemset/get-filters";
import {setFiltersViewModel} from "../store/actions/tableFiltersSlice";
import {store} from "../../../store/store";

export default async function getFilters() {
  // if (store.getState().filters.isAvailable) return;
  try {
    const res = await getFiltersAPI();
    store.dispatch(setFiltersViewModel(res));
  } catch (e) {
    console.log(e);
    // throw e;
  }
}
