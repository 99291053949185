import {Box, Stack} from "@mui/material";
import QuestionNavigationTopbarUI from "./QuestionNavigationTopbar";
import ExamOverviewUI from "./ExamOverviewUI";
import QuestionNavigationUI from "./QuestionNavigationUI";
import {
  ExamDataType,
  QuestionsDataType,
} from "../../../../api/attempt-exam/get-exam";
import {ExamQuestionStatusType} from "./QuestionNavigationButtonUI";
import {useGetExamQuery} from "../../store/api/attemptExamDataApiSlice";

function convertExamDataToNavigationUI(examData: ExamDataType) {
  // 1) Group questions by their qSection
  const grouped = examData.questions.reduce<
    Record<string, QuestionsDataType[]>
  >((acc, question) => {
    const section = question.qSection;
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(question);
    return acc;
  }, {});

  // 2) Convert the grouped object to an array of { qSection, qList }
  return Object.entries(grouped).map(([section, qList]) => ({
    qSection: section,
    qList,
  }));
}

function convertExamDataToOverviewUI(examData: ExamDataType) {
  let answered = 0,
    bookmarked = 0,
    notVisited = 0,
    notAnswered = 0;
  for (const q of examData.questions) {
    if (q.status === ExamQuestionStatusType.ANSWERED) answered++;
    else if (q.status === ExamQuestionStatusType.NOT_VISITED) notVisited++;
    else if (q.status === ExamQuestionStatusType.NOT_ANSWERED) notAnswered++;

    if (q.isBookmarked) bookmarked++;
  }
  return {answered, notAnswered, notVisited, bookmarked};
}

interface RightPanelMainProps {
  examId: string;
}
export function RightPanelMain({examId}: RightPanelMainProps) {
  const {data} = useGetExamQuery({examId});
  if (data == undefined) return <></>;

  const qNavigationData = convertExamDataToNavigationUI(data);
  const overviewProps = convertExamDataToOverviewUI(data);

  return (
    <Stack spacing={2} pb={5}>
      <QuestionNavigationTopbarUI examName={data.examName} />

      <Box height={1} />

      <ExamOverviewUI {...overviewProps} />

      {qNavigationData.map((qData, i) => (
        <>
          <Box height={2} />
          <QuestionNavigationUI
            key={i}
            qSection={qData.qSection}
            qList={qData.qList}
          />
        </>
      ))}
    </Stack>
  );
}
