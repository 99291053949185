import {SimplePaletteColorOptions} from "@mui/material/styles";
import {AttemptStatusType} from "../enums";

type StatusColorOptions = {
  [AttemptStatusType.Unseen]: SimplePaletteColorOptions | undefined;
  [AttemptStatusType.Seen]: SimplePaletteColorOptions;
  [AttemptStatusType.Correct]: SimplePaletteColorOptions;
  [AttemptStatusType.Incorrect]: SimplePaletteColorOptions;
};

export const statusTheme: StatusColorOptions = {
  [AttemptStatusType.Unseen]: undefined,
  [AttemptStatusType.Seen]: {
    main: "#FFB800",
  },
  [AttemptStatusType.Correct]: {
    main: "#027A48",
  },
  [AttemptStatusType.Incorrect]: {
    main: "#E44555",
  },
};
