import {configureStore} from "@reduxjs/toolkit";
import examSliceReducer from "./actions/examSlice";
import userSliceReducer from "./actions/userSlice";
import loginDialogSlice from "./actions/loginDialogSlice";
import problemDataSliceReducer from "../features/problem-dialog/store/actions/problemContentSlice";
import tableDataSliceReducer from "../features/problemset-table/store/actions/tableDataSlice";
import tableFiltersSliceReducer from "../features/problemset-table/store/actions/tableFiltersSlice";
import deviceDetailsSliceReducer from "./actions/deviceDetailsSlice";
import searchDataReducer from "../features/mock-test-table/store/actions/searchDataSlice";
import {mockTestApiSlice} from "../features/mock-test-table/store/api/mockTestApiSlice";
import {attemptExamDataApiSlice} from "../features/attempt-exam/store/api/attemptExamDataApiSlice";
import {examConfigApiSlice} from "./api/examConfigApiSlice";

export const store = configureStore({
  reducer: {
    examSession: examSliceReducer,
    user: userSliceReducer,
    loginDialog: loginDialogSlice,
    problemData: problemDataSliceReducer,
    tableData: tableDataSliceReducer,
    filters: tableFiltersSliceReducer,
    deviceDetails: deviceDetailsSliceReducer,
    searchData: searchDataReducer,
    [examConfigApiSlice.reducerPath]: examConfigApiSlice.reducer,
    [mockTestApiSlice.reducerPath]: mockTestApiSlice.reducer,
    [attemptExamDataApiSlice.reducerPath]: attemptExamDataApiSlice.reducer,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware().concat(
      examConfigApiSlice.middleware,
      mockTestApiSlice.middleware,
      attemptExamDataApiSlice.middleware,
    );
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
