import {useRef, useState} from "react";
import {Box, IconButton, Paper} from "@mui/material";
import {STYLES} from "./styles";
import SearchIconProblemsetSvg from "../../../../assets/icons/custom/search_icon_problemset";
import SearchInputUI from "./SearchInputUI";
import SearchButtonUI, {SearchButtonLabelViewModelType} from "./SearchButtonUI";
import SearchButtonDropdownMain from "./search-button-dropdown/search-button-dropdown-main";

interface SearchUIProps {
  searchData: {
    buttonLabelViewModel: SearchButtonLabelViewModelType | undefined;
  };
}
export default function SearchUI({searchData}: SearchUIProps) {
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const [isButtonDropdownOpen, setIsButtonDropdownOpen] = useState(false);

  return (
    <Paper component="form" sx={STYLES.searchPaper}>
      <Box ref={searchButtonRef} onClick={() => setIsButtonDropdownOpen(true)}>
        <SearchButtonUI
          onClick={() => {}}
          labelViewModel={searchData.buttonLabelViewModel}
        />
      </Box>
      <SearchButtonDropdownMain
        anchorRef={searchButtonRef}
        openState={[isButtonDropdownOpen, setIsButtonDropdownOpen]}
      />
      {/** SEARCH */}
      <Box
        id="search-input-box"
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        ml={"12px"}>
        <SearchInputUI />
        <IconButton
          type="button"
          sx={STYLES.searchIconButton}
          aria-label="search">
          <SearchIconProblemsetSvg sx={STYLES.searchSvgIcon} />
        </IconButton>
      </Box>
    </Paper>
  );
}
