import {instanceWithHeaders as axios} from "../../config/axios";
import {TableRowDataInterface} from "../../components/table/TableBodyUI";
import {
  TableCellInterface,
  TableCellViewType,
} from "../../components/table/TableCellUI";
import {TableFooterPaginationInterface} from "../../components/table/TableFooterUI";

type GetTestsListResponseType = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    reference_id: string;
    name: string;
    duration: string;
    start_datetime: string | null;
    end_datetime: string | null;
    test_type: string;
    status: string;
    total_questions: string;
    max_score: string;
    tags: {
      name: string;
    }[];
    marks_obtained?: string;
  }[];
};
export function convertGetTestsListResponseType(
  res: GetTestsListResponseType,
): {
  pagination: TableFooterPaginationInterface;
  rowsData: TableRowDataInterface[];
} {
  return {
    pagination: {
      next: res.next,
      previous: res.previous,
    },
    rowsData: res.results.map(row => {
      const status = getExamStatus(row.status);
      return {
        id: row.reference_id,
        data: [
          {
            ...getExamStatusIconCell(status),
          },
          {
            label: {name: row.name},
            tags: [{name: "Full Syllabus"}],
            cellType: TableCellViewType.TITLE_WITH_TAGS,
          },
          {
            label: {
              name: getExamScoreLabel(status, row.marks_obtained),
              styles: {fontStyle: "italic"},
            },
            tags: [
              {name: row.total_questions},
              {name: row.max_score},
              {name: row.duration, icon: "schedule"},
            ],
            cellType: TableCellViewType.TITLE_WITH_TAGS,
          },
          {
            ...getExamCTACell(status),
          },
        ],
      };
    }),
  };
}

export enum ExamStatus {
  UNATTEMPTED = "UA",
  IN_PROGRESS = "IP",
  COMPLETED = "C",
}

export function getExamStatus(val: string): ExamStatus {
  switch (val) {
    case ExamStatus.IN_PROGRESS:
      return ExamStatus.IN_PROGRESS;
    case ExamStatus.COMPLETED:
      return ExamStatus.COMPLETED;
    case ExamStatus.UNATTEMPTED:
      return ExamStatus.UNATTEMPTED;
    default:
      return ExamStatus.UNATTEMPTED;
  }
}

function getExamScoreLabel(status: ExamStatus, marksObtained?: string): string {
  switch (status) {
    case ExamStatus.IN_PROGRESS:
      return "End your test to view score";
    case ExamStatus.COMPLETED:
      return `Score: ${marksObtained}`;
    case ExamStatus.UNATTEMPTED:
      return `Score: --`;
  }
}

function getExamStatusIconCell(status: ExamStatus): TableCellInterface {
  switch (status) {
    case ExamStatus.IN_PROGRESS:
      return {
        icon: "pause",
        color: {main: "#FFB800"},
        cellType: TableCellViewType.ICON,
      };
    case ExamStatus.COMPLETED:
      return {
        icon: "check",
        color: {main: "#027A48"},
        cellType: TableCellViewType.ICON,
      };
    case ExamStatus.UNATTEMPTED:
      return {
        cellType: TableCellViewType.EMPTY,
      };
  }
}

function getExamCTACell(status: ExamStatus): TableCellInterface {
  switch (status) {
    case ExamStatus.IN_PROGRESS:
      return {
        label: {
          name: "Resume exam",
        },
        cellType: TableCellViewType.CTA,
      };
    case ExamStatus.COMPLETED:
      return {
        label: {
          name: "See analysis",
        },
        cellType: TableCellViewType.CTA,
      };
    case ExamStatus.UNATTEMPTED:
      return {
        label: {
          name: "",
        },
        cellType: TableCellViewType.CTA,
      };
  }
}

export default async function getTestsListAPI(query: string) {
  return await axios
    .get(`/test_series/exam_test/?${query}`)
    .then(res => {
      return convertGetTestsListResponseType(res.data);
    })
    .catch(err => {
      throw err;
    });
}
