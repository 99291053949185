import {Paper, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useParams} from "react-router-dom";
import {useGetMockTestDetailsQuery} from "../../../../../features/mock-test-table/store/api/mockTestApiSlice";
import {
  MockTestDetailsOverviewUI,
  MockTestDetailsOverviewViewModel,
} from "./details-page/MockTestDetailsOverviewUI";
import {ExamStatus} from "../../../../../api/mock-test/get-tests-list";

export interface MockTestDetailsProps {
  overview: MockTestDetailsOverviewViewModel;
  status: ExamStatus;
}
export default function MockTestDetailsPage() {
  const {testId} = useParams();
  const {data} = useGetMockTestDetailsQuery({
    testId: testId ?? "",
  });

  return (
    <Grid container justifyContent="center">
      <Grid size={0.25} />
      <Grid size={8.5}>
        <Stack spacing={4}>
          <MockTestDetailsOverviewUI
            testId={testId ?? ""}
            viewModel={data?.overview}
          />
          <MockTestInstructionsUI />
        </Stack>
      </Grid>
      <Grid size={0.25} />
      <Grid size={2.75}>
        <Stack spacing={2}></Stack>
      </Grid>
      <Grid size={0.25} />
    </Grid>
  );
}

/** Section: Instructions */
function MockTestInstructionsUI() {
  return (
    <Paper variant="feature" sx={{p: "20px"}}>
      <Typography component="p" variant="body1" fontWeight="600">
        Instructions
      </Typography>
    </Paper>
  );
}
