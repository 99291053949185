import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {STYLES} from "./styles";

export default function TableSkeletonUI() {
  return (
    <TableContainer sx={STYLES.tableContainer}>
      <Table sx={STYLES.table} aria-label="loading-table">
        <TableHeaderSkeletonUI />
        <TableBodySkeletonUI numberOfColumns={4} />
      </Table>
      <Box height={"16px"} />
      <TableFooterSkeletonUI />
    </TableContainer>
  );
}

function TableHeaderSkeletonUI() {
  return (
    <>
      <colgroup>
        <col width="60%" />
        <col width="15%" />
        <col width="15%" />
        <col width="10%" />
      </colgroup>
      <TableHead>
        <TableRow sx={STYLES.tableHeadRow}>
          {Array.from({length: 4}).map((_, index) => (
            <TableCell key={index} align="left">
              <Skeleton
                variant="rectangular"
                width="64px"
                sx={{borderRadius: "12px"}}
              />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

interface TableBodySkeletonUIProps {
  numberOfColumns: number;
}
export function TableBodySkeletonUI({
  numberOfColumns,
}: TableBodySkeletonUIProps) {
  return (
    <TableBody>
      {Array.from({length: 10}).map((_, rowIdx) => (
        <TableRow key={rowIdx} sx={STYLES.tableBodyRowSkeleton}>
          {Array.from({length: numberOfColumns}).map((_, colIdx) => (
            <TableCell align="center" key={colIdx}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height="74px"
                sx={{
                  borderTopLeftRadius: colIdx === 0 ? "12px" : "0",
                  borderBottomLeftRadius: colIdx === 0 ? "12px" : "0",
                  borderTopRightRadius:
                    colIdx === numberOfColumns - 1 ? "12px" : "0",
                  borderBottomRightRadius:
                    colIdx === numberOfColumns - 1 ? "12px" : "0",
                }}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

function TableFooterSkeletonUI() {
  return (
    <Stack direction={"row"} width={"100%"} gap={"12px"} justifyContent={"end"}>
      <Skeleton
        variant="rectangular"
        width="81px"
        height={"33px"}
        sx={{borderRadius: "8px"}}
      />
      <Skeleton
        variant="rectangular"
        width="64px"
        height={"33px"}
        sx={{borderRadius: "8px"}}
      />
    </Stack>
  );
}
