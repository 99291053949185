import {Button, Paper, Stack} from "@mui/material";
import {MaterialSymbol} from "react-material-symbols";
import {usePage} from "../hooks/AttemptExamProvider";
import {
  useUpdateQuestionBookmarkMutation,
  useUpdateQuestionUserResponseMutation,
} from "../store/api/attemptExamDataApiSlice";

interface FooterUIProps {
  examId: string;
}
export default function FooterUI({examId}: FooterUIProps) {
  const {
    currentQuestionData,
    qNumber,
    setQNumber,
    isNextDisabled,
    isPreviousDisabled,
  } = usePage();

  const isBookmarked = currentQuestionData?.isBookmarked ?? false;

  const [updateBookmark] = useUpdateQuestionBookmarkMutation();
  const [updateUserResponse] = useUpdateQuestionUserResponseMutation();

  return (
    <Paper
      variant="feature"
      sx={{
        position: "absolute",
        width: "100%",
        bottom: 0,
        borderEndEndRadius: 0,
        borderEndStartRadius: 0,
      }}>
      <Stack direction="row" justifyContent="space-between" py={3} px={5}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setQNumber(qNumber - 1)}
            disabled={isPreviousDisabled}
            sx={{px: 4, py: 1, fontWeight: 600}}>
            Previous
          </Button>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              updateUserResponse({
                examId,
                questionNumber: qNumber,
                userResponse: null,
              });
            }}
            sx={{px: 2, py: 1, fontWeight: 500}}>
            Clear response
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={{bgcolor: "warning.main", px: 2, py: 1, fontWeight: 700}}
            startIcon={
              <MaterialSymbol
                icon="bookmark"
                size={20}
                weight={500}
                fill={isBookmarked}
              />
            }
            onClick={() => {
              updateBookmark({
                examId: examId,
                questionNumber: qNumber,
                isBookmarked: !isBookmarked,
              });
            }}>
            Mark for review
          </Button>
          <Button
            variant="contained"
            disabled={isNextDisabled}
            sx={{bgcolor: "success.main", px: 4, py: 1, fontWeight: 700}}
            onClick={() => setQNumber(qNumber + 1)}
            disableElevation>
            Save & Next
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}
