import {Stack} from "@mui/material";
import Grid from "@mui/material/Grid2";
import FooterUI from "./components/FooterUI";
import NavbarUI from "./components/NavbarUI";
import {RightPanelMain} from "./components/right-panel/right-panel-main";
import {QuestionPanelMain} from "./components/question-panel/question-panel-main";
import {AttemptExamProvider} from "./hooks/AttemptExamProvider";

interface AttemptExamFeatureProps {
  examId: string;
  questionsCount: number;
}
export default function AttemptExamFeature({
  examId,
  questionsCount,
}: AttemptExamFeatureProps) {
  return (
    <AttemptExamProvider maxPage={questionsCount} examId={examId}>
      <Grid container justifyContent="center">
        <Grid size={0.5} />
        <Grid size={7.5} height={"100vh"} position={"relative"}>
          <Stack spacing={4}>
            <NavbarUI />
            <QuestionPanelMain examId={examId} />
          </Stack>
          <FooterUI examId={examId} />
        </Grid>
        <Grid size={0.5} />
        <Grid
          size={3.5}
          sx={{
            color: "primary.contrastText",
            bgcolor: "white",
            height: "100vh",
            px: 3,
            pt: 1,
            overflowY: "scroll",
            position: "relative",
          }}>
          <RightPanelMain examId={examId} />
        </Grid>
      </Grid>
    </AttemptExamProvider>
  );
}
