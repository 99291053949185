import {SimplePaletteColorOptions} from "@mui/material";
import {instanceWithHeaders as axios} from "../../config/axios";
import {
  AttemptStatusFilterType,
  ChapterFilterType,
  DifficultyFilterType,
  PatternFilterType,
  SubjectFilterType,
  TopicFilterType,
} from "../../features/problemset-table/store/actions/tableFiltersSlice";
import {SymbolCodepoints} from "react-material-symbols";
import {
  stringToAttemptStatusType,
  stringToProblemDifficultyType,
} from "../../config/enums";
import {statusTheme} from "../../config/themes/status-theme";
import {statusIcons} from "../../assets/icons/ProblemIcons";
import {difficultyTheme} from "../../config/themes/difficulty-theme";
import {
  ChapterContentType,
  SubjectContentType,
  SyllabusStructType,
  TopicContentType,
} from "../../features/problemset-table/store/actions/SyllabusStructType";
import {PyqMockDropdownYearListType} from "../../features/mock-test-table/components/search/search-button-dropdown/PyqMockDropdownUI";

type NameIdType = {
  name: string;
  reference_id: string;
  metadata: {
    icon: SymbolCodepoints;
    color: SimplePaletteColorOptions;
    altText: string;
  };
};
type ChapterType = {
  name: string;
  reference_id: string;
  questions_count: number;
  topics_count: number;
  metadata: {
    icon: SymbolCodepoints;
    color: SimplePaletteColorOptions;
    altText: string;
  };
};
type ResponseType = {
  subjects?: NameIdType[];
  chapters?: Record<string, ChapterType[]>;
  topics?: Record<string, NameIdType[]>;
  question_types?: NameIdType[];
  user_question_status?: NameIdType[];
  difficulty_types?: NameIdType[];
  previous_years?: {id: string; label: string; description: string}[];
};

/** ReturnType */
type ReturnType = {
  syllabus?: SyllabusStructType;
  subjects: SubjectFilterType[];
  chapters: Record<SubjectFilterType["id"], ChapterFilterType[]>;
  topics: Record<ChapterFilterType["id"], TopicFilterType[]>;
  patterns: PatternFilterType[];
  attemptStatus: AttemptStatusFilterType[];
  difficulty: DifficultyFilterType[];
  pyqMockYearList: PyqMockDropdownYearListType[];
};

function convertResultToData(res: ResponseType): ReturnType {
  const topics: Map<ChapterFilterType["id"], TopicFilterType[]> = new Map();
  const chapters: Map<SubjectFilterType["id"], ChapterFilterType[]> = new Map();
  const subjects =
    res.subjects?.map(item => {
      /** Chapters */
      const chaptersPerSubject = res.chapters?.[item.reference_id] ?? [];
      chapters.set(
        item.reference_id,
        chaptersPerSubject.map(chapter => {
          /** Topics */
          const topicsPerChapter = res.topics?.[chapter.reference_id];
          if (topicsPerChapter !== undefined) {
            topics.set(
              chapter.reference_id,
              topicsPerChapter.map(topic => {
                return {
                  id: topic.reference_id,
                  viewModel: {
                    id: topic.reference_id,
                    name: topic.name,
                  },
                };
              }),
            );
          }

          /** -- **/
          return {
            id: chapter.reference_id,
            questionsCount: chapter.questions_count,
            topicsCount: chapter.topics_count,
            viewModel: {
              id: chapter.reference_id,
              name: chapter.name,
            },
          };
        }),
      );
      return {
        id: item.reference_id,
        viewModel: {
          id: item.reference_id,
          name: item.name,
          icon: item.metadata.icon,
          color: item.metadata.color,
          altText: item.metadata.altText,
        },
      };
    }) ?? [];

  const subjectContentMap = new Map<string, SubjectContentType>();
  // subject's response map
  res.subjects?.map((subject, subIdx) => {
    const chapterContentMap = new Map<string, ChapterContentType>();
    // chapters's response map
    res.chapters?.[subject.reference_id]?.map((chapter, chapIdx) => {
      const topicContentMap = new Map<string, TopicContentType>();
      // topics's response map
      res.topics?.[chapter.reference_id]?.map(topic => {
        // set topics
        topicContentMap.set(topic.reference_id, {
          id: topic.reference_id,
          viewModel: {
            id: topic.reference_id,
            name: topic.name,
          },
          isSelected: false,
        });
      });

      // set chapters
      chapterContentMap.set(chapter.reference_id, {
        id: chapter.reference_id,
        viewModel: {
          id: chapter.reference_id,
          name: chapter.name,
          icon: chapter.metadata.icon,
          color: chapter.metadata.color,
          // altName: chapter.metadata.altText,
        },
        isSelected: chapIdx === 0,
        questionsCount: chapter.questions_count,
        topicsCount: chapter.topics_count,
        topicsMap: Object.fromEntries(topicContentMap),
        selectedTopics: [],
      });
    });

    const firstChapterId =
      res.chapters?.[subject.reference_id]?.[0].reference_id;
    // set subjects
    subjectContentMap.set(subject.reference_id, {
      id: subject.reference_id,
      viewModel: {
        id: subject.reference_id,
        name: subject.name,
        icon: subject.metadata.icon,
        color: subject.metadata.color,
        altName: subject.metadata.altText,
      },
      isSelected: subIdx === 0 ? true : false,
      chaptersMap: Object.fromEntries(chapterContentMap),
      selectedChapters: firstChapterId ? [firstChapterId] : [],
    });
  });

  return {
    syllabus: {
      subjectsMap: Object.fromEntries(subjectContentMap),
      selectedSubjects: res.subjects ? [res.subjects[0].reference_id] : [],
    },
    /** SUBJECTS */
    subjects: subjects,
    /** CHAPTERS */
    chapters: Object.fromEntries(chapters),
    /** TOPICS */
    topics: Object.fromEntries(topics),
    /** PATTERN */
    patterns:
      res.question_types?.map(item => {
        return {
          id: item.reference_id,
          viewModel: {id: item.reference_id, name: item.name},
        };
      }) ?? [],
    /** DIFFICULTY */
    difficulty:
      res.difficulty_types?.map(item => {
        const type = stringToProblemDifficultyType(item.reference_id);
        return {
          id: item.reference_id,
          viewModel: {
            id: type,
            name: item.name,
            color: difficultyTheme[type],
          },
        };
      }) ?? [],
    /** STATUS */
    attemptStatus:
      res.user_question_status?.map(item => {
        const type = stringToAttemptStatusType(item.reference_id);
        return {
          id: item.reference_id,
          viewModel: {
            id: type,
            name: item.name,
            color: statusTheme[type],
            icon: statusIcons[type] ?? undefined,
          },
        };
      }) ?? [],
    pyqMockYearList:
      res.previous_years?.map((item, idx) => {
        return {...item, isSelected: idx === 0};
      }) ?? [],
  };
}

export default async function getFiltersAPI() {
  return await axios
    .get(`/core/content/`)
    .then(res => {
      return convertResultToData(res.data);
    })
    .catch(err => {
      throw err;
    });
}
export type GetFiltersAPIReturnType = ReturnType;
