import {Stack} from "@mui/material";
import Grid from "@mui/material/Grid2";
import MockTestTableFeature from "../../../../../features/mock-test-table";
import MockTestCategoryFeature from "../../../../../features/mock-test-category";
import {AppDispatch} from "../../../../../store/store";
import {useDispatch} from "react-redux";
import {setSearchButtonLabel} from "../../../../../features/mock-test-table/store/actions/searchDataSlice";
import {useEffect} from "react";
import {
  mockTestCategoryDetails,
  MockTestCategoryType,
} from "../../../../../features/mock-test-category/config/mock-test-config";
import {useExamPageNavigate} from "../../navigation/useExamPageNavigate";
import {ExamPageSection} from "../../navigation/routes";
import {useGetMockTestCategory} from "../../../../../features/mock-test-category/config/useGetMockTestCategory";
import {useUpdatePyqYearSelection} from "../../../../../store/api/utils/updatePyqYearSelection";
import {useGetMockTestConfig} from "../../../../../features/mock-test-table/utils/useGetMockTestConfig";

export default function MockTestPage() {
  // If the mock test category is not valid, navigate to the explore page with default mock test category
  const {isValid, mockTestCategoryType} = useGetMockTestCategory();
  const examPageNavigate = useExamPageNavigate();
  useEffect(() => {
    if (!isValid) {
      examPageNavigate(
        ExamPageSection.MOCK_TEST_EXPLORE_WITH_CATEGORY,
        {
          mockTestCategoryId:
            mockTestCategoryDetails[mockTestCategoryType].routeId,
        },
        undefined,
        true,
      );
    }
  }, [isValid, mockTestCategoryType]);

  // Handle filter query param UPDATES & search button STORE
  useUpdatePyqYearSelection();
  const {year} = useGetMockTestConfig();
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    switch (mockTestCategoryType) {
      case MockTestCategoryType.PYQ:
        dispatch(
          setSearchButtonLabel({
            id: year?.id ?? "",
            name: year?.label ?? "",
            icon: "event",
          }),
        );
        break;
      default:
        dispatch(setSearchButtonLabel(undefined));
        break;
    }
  }, [year, mockTestCategoryType]);

  return (
    <Grid container justifyContent="center">
      <Grid size={0.25} />
      <Grid size={8.5}>
        <Stack spacing={2}>
          <MockTestCategoryFeature />
          {isValid && <MockTestTableFeature />}
        </Stack>
      </Grid>
      <Grid size={0.25} />
      <Grid size={2.75}>
        <Stack spacing={2}></Stack>
      </Grid>
      <Grid size={0.25} />
    </Grid>
  );
}
