import {useGetExamConfigQuery} from "../../../store/api/examConfigApiSlice";

export const useGetMockTestConfig = () => {
  const {data: examConfig} = useGetExamConfigQuery();
  const year = examConfig?.pyqMock.years.find(list => list.isSelected);

  return {
    year: year,
  };
};
