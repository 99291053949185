import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

export default function SearchIconProblemsetSvg(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.0752 15.3716L11.9951 12.2915M13.6591 8.29095C13.6591 11.4194 11.123 13.9554 7.99457 13.9554C4.86616 13.9554 2.33008 11.4194 2.33008 8.29095C2.33008 5.16254 4.86616 2.62646 7.99457 2.62646C11.123 2.62646 13.6591 5.16254 13.6591 8.29095Z"
          stroke="#757575"
          strokeWidth="1.41612"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
