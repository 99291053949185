import {Box, Paper, Stack} from "@mui/material";
import TableUI from "../../components/table/TableUI";
import SearchUI from "./components/search/SearchUI";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {useGetMockTestListQuery} from "./store/api/mockTestApiSlice";
import {TableHeaderDataInterface} from "../../components/table/TableHeaderUI";
import {STYLES} from "./styles";
import {useGetQueryParam} from "../../utils/navigation/useQueryParam";
import {FilterType} from "../../config/enums";
import {useExamPageNavigate} from "../../pages/exam/common/navigation/useExamPageNavigate";
import {ExamPageSection} from "../../pages/exam/common/navigation/routes";
import {useGetMockTestCategory} from "../mock-test-category/config/useGetMockTestCategory";
import {useGetMockTestConfig} from "./utils/useGetMockTestConfig";

const headerData: TableHeaderDataInterface[] = [
  {label: "", widthPercentage: "6.5%", align: "center"},
  {label: "Mock Test", widthPercentage: "48.5%", align: "left"},
  {label: "Overview", widthPercentage: "30%", align: "left"},
  {label: "", widthPercentage: "15%", align: "left"},
];

export default function MockTestTableFeature() {
  const {mockTestCategoryType} = useGetMockTestCategory();
  const page = useGetQueryParam(FilterType.PAGE);
  const examPageNavigate = useExamPageNavigate();

  const {
    searchData: {searchData},
  } = useSelector((state: RootState) => state);

  const {year} = useGetMockTestConfig();
  const {data: mockTestTableData, isFetching} = useGetMockTestListQuery({
    mockTestCategoryId: mockTestCategoryType,
    year: year?.id ?? "",
    page: page,
  });

  const tableData = {
    isLoading: isFetching,
    headerData: headerData,
    rowsData: mockTestTableData?.rowsData ?? null,
    pagination: mockTestTableData?.pagination ?? {previous: null, next: null},
    onRowClick: (id: string) => {
      examPageNavigate(ExamPageSection.MOCK_TEST_INSTRUCTIONS, {testId: id});
    },
  };

  return (
    <Paper variant="feature" sx={STYLES.mockTestTableFeaturePaper}>
      <Stack direction="row" justifyContent="space-between">
        <SearchUI searchData={searchData} />
      </Stack>

      <Box height={"16px"} />

      <TableUI {...tableData} />
    </Paper>
  );
}
