import {InputBase} from "@mui/material";
import React from "react";
import {STYLES} from "./styles";

interface SearchInputUIProps {
  inputBaseRef?: React.RefObject<HTMLInputElement>;
  setSearchDropdownIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function SearchInputUI({
  inputBaseRef,
  setSearchDropdownIsOpen,
}: SearchInputUIProps) {
  return (
    <InputBase
      // inputRef={inputBaseRef}
      // onClick={() => setSearchDropdownIsOpen(true)}
      placeholder={`Search for tests`}
      inputProps={{"aria-label": "search"}}
      size="small"
      sx={{width: "100%"}}
      slotProps={{input: {sx: STYLES.slotInputProps}}}
      // onFocus={() => setSearchDropdownIsOpen(true)}
      // onBlur={() => setSearchDropdownIsOpen(false)}
    />
  );
}
