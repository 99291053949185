import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {examDetails, getExamTypeFromRouteName} from "../../../config/exam";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../store/store";
import {setExam} from "../../../store/actions/examSlice";
import Navbar from "../../../features/navbar";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import getFilters from "../../../features/problemset-table/handlers/getFilters";
import {useGetExamConfigQuery} from "../../../store/api/examConfigApiSlice";

export default function ExamPage() {
  const {examPath} = useParams();
  const examType = getExamTypeFromRouteName(examPath);

  const dispatch = useDispatch<AppDispatch>();

  const {isLoading} = useGetExamConfigQuery();

  React.useEffect(() => {
    if (examType !== null) dispatch(setExam(examDetails[examType].type));

    getFilters();

    return () => {
      dispatch(setExam(null));
    };
  }, [examType]);

  return examType === null ? (
    <Navigate to="/" />
  ) : (
    <Box
      sx={{
        backgroundColor: "primary.light",
        width: "100vw",
        minHeight: "100vh",
      }}>
      <Box height="16px" />
      <Navbar />
      <Box height="32px" />
      {!isLoading && <Outlet />}
      <Box height="32px" />
    </Box>
  );
}
