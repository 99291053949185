import {useParams} from "react-router-dom";
import {Box} from "@mui/material";
import AttemptExamFeature from "../../../../../features/attempt-exam";
import {useGetExamQuery} from "../../../../../features/attempt-exam/store/api/attemptExamDataApiSlice";
import FullScreenLoaderSpinner from "../../../../../components/full-screen-loader-spinner";

export default function AttemptExamPage() {
  const {testId} = useParams();

  const examId = testId ?? "";
  const {isLoading, data} = useGetExamQuery({examId});
  return (
    <Box
      sx={{
        backgroundColor: "primary.light",
        width: "100vw",
        minHeight: "100vh",
      }}>
      {isLoading ? (
        <FullScreenLoaderSpinner />
      ) : (
        <AttemptExamFeature
          examId={examId}
          questionsCount={data?.questionsCount ?? 0}
        />
      )}
    </Box>
  );
}
