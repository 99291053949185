import {ButtonViewModel} from "../../config/type";
import {MockTestDetailsProps} from "../../pages/exam/common/section/mock-test/MockTestDetailsPage";
import {ExamStatus, getExamStatus} from "./get-tests-list";

interface TestDetailsResponse {
  instructions: string | null;
  reference_id: string;
  name: string;
  duration: string;
  start_datetime: string | null;
  end_datetime: string | null;
  test_type: string;
  status: string;
  total_questions: string;
  max_score: string;
  tags: Array<{
    name: string;
  }>;
  marks_obtained: number | null;
}

export function convertGetTestDetailsResponseType(
  res: TestDetailsResponse,
): MockTestDetailsProps {
  const status = getExamStatus(res.status);
  return {
    status: status,
    overview: {
      title: res.name,
      subTitle: "Previous year paper",
      tags: [
        {
          icon: "quiz",
          label: res.total_questions,
        },
        {
          icon: "assignment",
          label: res.max_score,
        },
        {
          icon: "schedule",
          label: res.duration,
        },
      ],
      primaryButton: {
        viewModel: getPrimaryButtonViewModel(status),
      },
    },
  };
}

function getPrimaryButtonViewModel(status: ExamStatus): ButtonViewModel {
  switch (status) {
    case ExamStatus.IN_PROGRESS:
      return {
        label: {name: "Resume test"},
        styles: {backgroundColor: "warning.main"},
        icon: {icon: "play_arrow", fill: true},
      };
    case ExamStatus.UNATTEMPTED:
      return {
        label: {name: "Take test"},
        styles: {backgroundColor: "success.main"},
      };
    case ExamStatus.COMPLETED:
      return {
        label: {name: "Re-attempt test"},
        styles: {backgroundColor: "success.main"},
      };
    default:
      return {
        label: {name: "Unavailable"},
        styles: {backgroundColor: "secondary.main"},
      };
  }
}
