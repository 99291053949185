import {Button, Stack, Typography} from "@mui/material";
import {STYLES} from "./styles";
import {useSearchParams} from "react-router-dom";
import {FilterType} from "../../config/enums";

export type TableFooterPaginationInterface = {
  previous: string | null;
  next: string | null;
};
interface TableFooterUIProps {
  pagination: TableFooterPaginationInterface;
}
export default function TableFooterUI({
  pagination: {previous, next},
}: TableFooterUIProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const isNextDisabled = next === null;
  const isPreviousDisabled = previous === null;

  function onNavigateButtonClick(url: string) {
    const urlParams = new URL(url).searchParams;
    const newPage = urlParams.get(FilterType.PAGE);
    if (newPage) {
      searchParams.set(FilterType.PAGE, newPage);
    } else {
      searchParams.delete(FilterType.PAGE);
    }
    setSearchParams(searchParams);
  }

  return (
    <Stack direction={"row"} width={"100%"} gap={"12px"} justifyContent={"end"}>
      <Button
        variant={
          isNextDisabled && !isPreviousDisabled ? "contained" : "outlined"
        }
        size="small"
        onClick={() => {
          if (previous) {
            onNavigateButtonClick(previous);
          }
        }}
        sx={{
          opacity: isPreviousDisabled ? 0.25 : 1,
        }}
        disabled={isPreviousDisabled}
        disableElevation>
        <Typography sx={STYLES.tableFooterButtonTypography}>
          Previous
        </Typography>
      </Button>
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          if (next) {
            onNavigateButtonClick(next);
          }
        }}
        disabled={isNextDisabled}
        disableElevation>
        <Typography sx={STYLES.tableFooterButtonTypography}>Next</Typography>
      </Button>
    </Stack>
  );
}
