import {
  Box,
  Button,
  SimplePaletteColorOptions,
  Typography,
} from "@mui/material";
import {dropShadowTheme} from "../../../../config/themes/theme";

export enum OptionStateType {
  Default = "Default",
  Selected = "Selected",
  Correct = "Correct",
  Incorrect = "Incorrect",
}

type OptionsProps = {
  color: SimplePaletteColorOptions;
};
type ProblemOptionsProps = {
  [OptionStateType.Default]: OptionsProps;
  [OptionStateType.Selected]: OptionsProps;
  [OptionStateType.Correct]: OptionsProps;
  [OptionStateType.Incorrect]: OptionsProps;
};

const problemOptionsProps: ProblemOptionsProps = {
  [OptionStateType.Default]: {
    color: {
      main: "#FFFFFF",
      dark: "#D9D9D9",
      contrastText: "#464255",
    },
  },
  [OptionStateType.Selected]: {
    color: {
      main: "#E7F0FD",
      dark: "#106EBE",
      contrastText: "#E7F0FD",
    },
  },
  [OptionStateType.Correct]: {
    color: {
      main: "#FAFEF3",
      dark: "#01B328",
      contrastText: "#FAFEF3",
    },
  },
  [OptionStateType.Incorrect]: {
    color: {
      main: "#FFEFF2",
      dark: "#FF2D55",
      contrastText: "#FFEFF2",
    },
  },
};

interface ProblemOptionUIProps {
  type?: OptionStateType;
  optionIdx?: string;
  data: any;
  onClick: () => void;
}
export default function ProblemOptionUI({
  type = OptionStateType.Default,
  optionIdx = "",
  data,
  onClick,
}: ProblemOptionUIProps) {
  return (
    <Button
      variant="outlined"
      fullWidth
      onClick={onClick}
      startIcon={
        <Box
          sx={{
            borderRadius: "50%",
            height: "24px",
            width: "24px",
            bgcolor: problemOptionsProps[type].color.dark,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mr: "8px",
          }}>
          <Typography
            fontSize={"0.75rem"}
            color={problemOptionsProps[type].color.contrastText}>
            {optionIdx}
          </Typography>
        </Box>
      }
      sx={{
        color: "primary.contrastText",
        boxShadow: dropShadowTheme,
        bgcolor: problemOptionsProps[type].color.main,
        borderColor: problemOptionsProps[type].color.dark,
        borderRadius: "12px",
        display: "flex",
        flexDirection: "row",
        py: "16px",
        textAlign: "start",
        justifyContent: "flex-start",
        fontSize: "1rem",
        height: "100%",
      }}>
      {data}
    </Button>
  );
}
